import "./ProfileBody.css";
import { View } from "@aws-amplify/ui-react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import FollowMe from "./FollowMe/FollowMe";
import TTRPGGamesPlayed from "./TTRPGGamesPlayed/TTRPGGamesPlayed";
import ProfileAvailability from "./ProfileAvailability/ProfileAvailability";
import GamePreferences from "./GamePreferences/GamePreferences";
import OptionalQuestions from "./OptionalQuestions/OptionalQuestions";
import PersonalInformation from "./PersonalInformation/PersonalInformation";
import VTTSystems from "./VTTSystems/VTTSystems";
import history from "../../../../utils/history";
import { PopupConfirmation } from "components/PopupConfirmation";
import { useLocation, useSearchParams } from "react-router-dom";
import DirectMessages from "../DirectMessages/DirectMessages";

import ProfileIdentityTags from "components/ProfileInterview/ProfileIdentityTags";
import ProfileLanguages from "components/ProfileInterview/ProfileLanguages";
import PlayerBadges from "../PlayerBadges/PlayerBadges";
import PopupDeleteAccount from "../PopupDeleteAccount/PopupDeleteAccount";
import { Button } from "@mui/material";
import { Biography } from "./Biography/Biography";

const ProfileBody = ({
    state,
    renderQuestions,
    isEditing,
    changeEditState,
    editState,
    setEditState,
    setIsEditing,
    filterFields,
    loading,
    openSaveMessagingWarning,
    setOpenSaveMessagingWarning,
    onSave,
    deleteAccountState,
    isTablet,
    errorPopupMessage,
    showErrorPopup,
    setShowErrorPopup,
    onCancelEditing,
    isPublicProfile,
}) => {
    const currentLocation = useLocation() || { pathname: "/profile" };
    const [triggerExit, setTriggerExit] = useState({ onOk: true, path: "" });
    const [searchParams] = useSearchParams();
    const handleGoToIntendedPage = useCallback((location) => history.push(location), []);
    const [openSaveWarning, setOpenSaveWarning] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const blockHandler = useRef();

    const {
        closeAccountInput,
        setCloseAccountInput,
        handleCloseDeleteAccountPopup,
        handleDeleteAccount,
        openDeleteAccount,
        setOpenDeleteAccount,
    } = deleteAccountState;

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 767);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        const anchor = window.location.hash.slice(1);
        if (anchor) {
            const anchorEl = document.getElementById(anchor);
            if (anchorEl) {
                anchorEl.scrollIntoView();
            }
        }
    }, [isEditing, state]);

    useEffect(() => {
        if (triggerExit.onOk) {
            const path = triggerExit.path ? `${triggerExit.path}?${searchParams.toString()}` : "";

            if (path) {
                handleGoToIntendedPage(path);
            }
        }

        if (Object.keys(editState).length === 0) {
            setTriggerExit({ onOk: true, path: "" });
        }

        const hasChanges = Object.keys(editState).length > 0;

        if (hasChanges) {
            blockHandler.current = history.block((param) => {
                setTriggerExit((obj) => ({ ...obj, path: param.location.pathname }));

                if (
                    Object.keys(editState).length !== 0 &&
                    param.location.pathname !== currentLocation.pathname &&
                    currentLocation.pathname === "/edit-profile"
                ) {
                    setTriggerExit((obj) => ({ ...obj, onOk: false }));
                    setOpenSaveWarning(true);
                }

                return triggerExit.onOk;
            });
        }

        return () => {
            blockHandler.current?.();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [handleGoToIntendedPage, currentLocation.pathname, triggerExit.onOk, triggerExit.path, editState]);

    useEffect(() => {
        if (currentLocation.pathname !== "/edit-profile") {
            setEditState({});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentLocation.pathname]);

    if (!state) {
        return null;
    }
    return (
        <>
            <View data-testid="profile-body" className={`profile-body ${isEditing ? "edit-mode" : ""}`}>
                {!isEditing && <PlayerBadges profile={state?.profile} filterFields={filterFields} />}
                <DirectMessages
                    isEditing={isEditing}
                    changeEditState={changeEditState}
                    editState={editState}
                    state={state}
                />
                {isEditing && (
                    <Biography
                        bio={state?.profile?.bio?.[0]}
                        changeEditState={changeEditState}
                        setIsEditing={setIsEditing}
                    />
                )}
                <TTRPGGamesPlayed
                    gamesPlayed={state.profile.gamesPlayed}
                    isEditing={isEditing}
                    changeEditState={changeEditState}
                    filterFields={filterFields}
                    loading={loading}
                    isMobile={isMobile}
                />
                <VTTSystems
                    state={state}
                    isEditing={isEditing}
                    changeEditState={changeEditState}
                    editState={editState}
                    filterFields={filterFields}
                    loading={loading}
                />
                <ProfileAvailability
                    state={state}
                    renderQuestions={renderQuestions}
                    isEditing={isEditing}
                    changeEditState={changeEditState}
                    editState={editState}
                />
                <PersonalInformation
                    profile={state.profile}
                    isEditing={isEditing}
                    changeEditState={changeEditState}
                    isPublicProfile={isPublicProfile}
                />
                <FollowMe state={state} isEditing={isEditing} changeEditState={changeEditState} />
                <ProfileIdentityTags
                    state={state}
                    editState={editState}
                    isEditing={isEditing}
                    changeEditState={changeEditState}
                    isCreatingProfile={false}
                />
                <ProfileLanguages
                    state={state}
                    isEditing={isEditing}
                    changeEditState={changeEditState}
                    editState={editState}
                    loading={loading}
                    isCreatingProfile={false}
                    selectedOptions={editState.languages || state?.profile?.languages || state?.languages}
                />
                <GamePreferences
                    state={state}
                    renderQuestions={renderQuestions}
                    isEditing={isEditing}
                    changeEditState={changeEditState}
                    isMobile={isMobile}
                    isTablet={isTablet}
                />
                <OptionalQuestions
                    state={state}
                    renderQuestions={renderQuestions}
                    isEditing={isEditing}
                    changeEditState={changeEditState}
                    editState={editState}
                />
                {isEditing && (
                    <View>
                        <Button
                            variant="text"
                            color="error"
                            onClick={() => {
                                setOpenDeleteAccount(true);
                            }}>
                            CLOSE ACCOUNT
                        </Button>
                    </View>
                )}
            </View>
            <PopupConfirmation
                data-testid="popupConfirmation"
                open={openSaveWarning}
                setOpen={setOpenSaveWarning}
                title="Are you sure?"
                message="You have unsaved content and it will be lost. Do you really want to navigate away?"
                btnAccept="LEAVE THIS PAGE"
                btnReject="STAY ON THIS PAGE"
                setIsEditing={setIsEditing}
                onAccept={() => {
                    setTriggerExit((obj) => ({
                        ...obj,
                        onOk: true,
                    }));
                    setOpenSaveWarning(false);
                }}
            />

            <PopupConfirmation
                data-testid="popupConfirmationMessages"
                open={openSaveMessagingWarning}
                setOpen={setOpenSaveMessagingWarning}
                title="Are you sure?"
                message="If you take a short or long rest from messaging you won’t be able to  receive or send messages."
                btnAccept="YES, I WANT TO DISABLE"
                btnReject="CANCEL"
                setIsEditing={setIsEditing}
                onAccept={() => {
                    setOpenSaveMessagingWarning(false);
                    onSave?.({});
                }}
            />
            <PopupDeleteAccount
                open={openDeleteAccount}
                setOpen={setOpenDeleteAccount}
                title={"Are you sure?"}
                warning={"Heed this warning: "}
                warningDescription={"your data shall be forever lost on this perilous quest."}
                onClickConfirm={handleDeleteAccount}
                onClickCancel={handleCloseDeleteAccountPopup}
                validationString={"CLOSE ACCOUNT"}
                helperText={"To confirm this, type “CLOSE ACCOUNT”"}
                applyBtnText={"CLOSE ACCOUNT"}
                cancelBtnText={"Cancel"}
                value={closeAccountInput}
                onChangeValue={(e) => setCloseAccountInput(e.target.value)}
            />
            <PopupConfirmation
                open={showErrorPopup}
                setOpen={setShowErrorPopup}
                title="Unable To Save Changes"
                message={errorPopupMessage}
                btnAccept="BACK TO EDITION"
                btnReject="EXIT WITHOUT SAVING"
                handleCloseAlternative={() => {
                    setShowErrorPopup(false);
                    onCancelEditing();
                }}
            />
        </>
    );
};

export default ProfileBody;
