import "./VTTSystems.css";
import { Text, View } from "@aws-amplify/ui-react";
import { Chip, CircularProgress } from "@mui/material";
import ProfileVTTQuestion from "components/ProfileInterview/ProfileVTTQuestion";
import React from "react";

const VTTSystems = ({ state, changeEditState, isEditing, editState, filterFields, loading }) => {
    const systemsList = () => {
        const VTTOptions = filterFields?.vtt || [];

        let vttSystems = state?.profile?.vtt || [];

        if (vttSystems.length > 0) {
            const vttLabels = VTTOptions.map((item) => item.label || item.name);
            const otherVTT = vttSystems.filter((vtt) => !vttLabels.includes(vtt) && vtt !== "Other");
            if (otherVTT.length > 0) {
                vttSystems = vttSystems.filter((vtt) => vttLabels.includes(vtt));
                vttSystems.push(`Other: ${otherVTT}`);
            }
        }

        return (
            <View className="systems-list">
                {vttSystems.length > 0 ? (
                    vttSystems.map((system) => {
                        const vttImg = VTTOptions.find((option) => option.name === system)?.img;

                        return (
                            <Chip
                                label={system}
                                key={system}
                                icon={vttImg && <img src={"/vtts/" + vttImg} alt={system} className="vtt-icon" />}
                            />
                        );
                    })
                ) : (
                    <Text className="none-selected">None Selected</Text>
                )}
            </View>
        );
    };

    return (
        <View
            id="gaming-platforms"
            data-testid="vtt-systems"
            className={`VTT-systems-container profile-component ${isEditing ? "edit-mode" : ""}`}>
            <Text className="VTT-systems-head-text" as="h2">
                Gaming Platforms
            </Text>
            {loading ? (
                <CircularProgress />
            ) : isEditing ? (
                <ProfileVTTQuestion
                    state={state?.profile}
                    isEditing={isEditing}
                    changeEditState={changeEditState}
                    editState={editState}
                    filterFields={filterFields}
                    loading={loading}
                />
            ) : (
                systemsList()
            )}
        </View>
    );
};

export default VTTSystems;
