import { View, Text } from "@aws-amplify/ui-react";

import "./PlayerBadges.css";
import { useState, useEffect, useMemo } from "react";
import { ShouldRender } from "../ShouldRender/ShouldRender";
import { awsFileURL } from "utils/functions";
import { Tooltip } from "@mui/material";
import { isBoth, isOnlyGameMaster, isOnlyPlayer, isProfessionalGameMaster } from "utils/isGameMaster";
import PlayerIcon from "components/Profile/PlayerIcon";
import GMIcon from "components/Profile/GMIcon";
import { isFilled } from "utils/functions";

export default function PlayerBadges({ profile, filterFields }) {
    const [badges, setBadges] = useState([]);

    const badgesList = filterFields?.badgesOptions;
    const vttList = filterFields?.vtt;

    useEffect(() => {
        try {
            if (!isFilled(badgesList, vttList)) return;

            const playerBadges = [];
            if (profile.badges) {
                profile?.badges?.forEach((badge) => {
                    const found = badgesList?.find((b) => b.id === badge);
                    if (found) playerBadges.push({ ...found, type: "badge" });
                });
            }

            const vttWithImages = vttList?.filter((vtt) => vtt?.img);
            if (profile.vtt) {
                profile?.vtt?.forEach((vtt) => {
                    const foundVtt = vttWithImages?.find((x) => x.name === vtt);

                    if (foundVtt)
                        playerBadges.push({
                            name: vtt,
                            type: "vtt",
                            id: foundVtt.id,
                            img: `/vtts/${foundVtt.img}`,
                            keepImageUrl: true,
                        });
                });
            }

            const isPlayer = isOnlyPlayer(profile);
            const isGM = isOnlyGameMaster(profile);
            const isPlayerAndGm = isBoth(profile);

            if (isPlayer)
                playerBadges.push({ name: "Player", id: "player", component: <PlayerIcon className="player-badge" /> });

            if (isGM)
                playerBadges.push({ name: "Player", id: "player-gm", component: <GMIcon className="player-badge" /> });

            if (isPlayerAndGm) {
                playerBadges.push({ name: "Player", id: "player", component: <PlayerIcon className="player-badge" /> });
                playerBadges.push({ name: "Player", id: "player-gm", component: <GMIcon className="player-badge" /> });
            }

            setBadges(playerBadges);
        } catch (error) {
            console.log("user has no badges", error);
        }
    }, [badgesList, vttList, profile]);

    const getBadgeFilterUrl = (badge) => {
        if (badge.type === "vtt") return `/user-list?vtt=${badge.id}`;
        if (badge.type === "badge") return `/user-list?badges=${badge.id}`;
        return "";
    };

    const Badges = () => {
        const baseUrl = awsFileURL();

        return badges?.map((badge) => {
            //TODO: after remove frogod from all profiles, remove the line below
            if (badge?.id == 21) return;

            if (badge?.component) return badge.component;

            const tooltip = `This user has an exclusive ${badge?.name} badge for having their account linked to their RPGMatch profile.`;
            const imgURL = badge?.keepImageUrl ? badge?.img : `${baseUrl}/badges/${badge?.img}`;

            return (
                <Tooltip key={`${badge?.name}-badge`} placement="top-start" title={tooltip}>
                    <a href={getBadgeFilterUrl(badge)}>
                        <img className="player-badge" alt={`${badge?.name}-badge`} key={badge?.id} src={imgURL} />
                    </a>
                </Tooltip>
            );
        });
    };

    if (!isFilled(badges)) return null;

    return (
        <View className="profile-component player-badges">
            <ShouldRender returnNull condition={isFilled(badges)} loading={!isFilled(badgesList, vttList)}>
                <Badges />
            </ShouldRender>
        </View>
    );
}
