import { Text, View } from "@aws-amplify/ui-react";
import ThingsLikedTTRPG from "components/Profile/components/ProfileBody/GamePreferences/ThingsLikedTTRPG";
import React from "react";
import "./GamePreferences.css";
import { gamePreferencesQuestions } from "@utils/constants";
import GamePreferencesEdit from "./GamePreferencesEdit";

const GamePreferences = ({ state, renderQuestions, isEditing, changeEditState, isMobile, isTablet }) => {
    return (
        <View
            id="games-preferences"
            data-testid="games-preference"
            className="game-preferences-container profile-component ">
            {isEditing ? (
                <GamePreferencesEdit
                    state={state}
                    changeEditState={changeEditState}
                    isMobile={isMobile}
                    isTablet={isTablet}
                />
            ) : (
                <View data-testid="game-preferences" className="game-preferences-container">
                    <Text as="h2" className="game-preferences-head-text">
                        Games Preferences
                    </Text>
                    <ThingsLikedTTRPG isMobile={isMobile} thingsLikedTTRPG={state.profile.thingsLikedTTRPG} />

                    {renderQuestions([...gamePreferencesQuestions], state)}
                </View>
            )}
        </View>
    );
};

export default GamePreferences;
