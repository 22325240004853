import { Autocomplete, Chip, CircularProgress, TextField } from "@mui/material";
import View from "@mui/material/Box";
import Text from "@mui/material/Typography";
import { AsyncFilterFieldsContext } from "context/AsyncFilterFieldsContext";
import { useContext, useEffect } from "react";

import Styles from "../../components/Profile/components/ProfileBody/IdentityTags/Styles.module.css";

import LoadingBtn from "components/LoadingBtn/LoadingBtn";

export default function ProfileLanguages({
    state,
    isEditing = false,
    isCreatingProfile = true,
    changeEditState,
    disabledValue,
    setDisabledValue,
    handleSaveValue,
    selectedOptions,
    setSelectedOptions,
    isLoading,
}) {
    const { filterFields, loading } = useContext(AsyncFilterFieldsContext);

    const hasState = (languagesId) => {
        //TODO: Check this languagesId -> this should recieve just a type of value, it's recieving <int> or boolean
        try {
            const languageObjects = filterFields?.languages?.filter((item) => languagesId?.includes(item.id)) || [];
            return languageObjects || [];
        } catch (error) {
            return [1];
        }
    };

    useEffect(() => {
        if (selectedOptions && selectedOptions.length === 0) {
            setDisabledValue?.(true);
        } else {
            setDisabledValue?.(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedOptions]);

    useEffect(() => {
        setSelectedOptions?.(hasState(state?.languages || state?.profile?.languages || isCreatingProfile));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterFields?.languages, state]);

    function handleChange(value) {
        setSelectedOptions?.(value);

        if (isEditing) {
            changeEditState({ languages: value.map((x) => x?.id || x) });
        }
    }

    function renderQuestionOrShowValues() {
        if (isEditing || isCreatingProfile) {
            return (
                <Autocomplete
                    data-testid="languages-tags"
                    value={selectedOptions}
                    multiple
                    getOptionLabel={(tag) =>
                        filterFields?.languages?.find((x) => x.id === tag)?.languageName || tag?.languageName
                    }
                    onChange={(event, newValue) => {
                        handleChange(newValue);
                    }}
                    id="languages"
                    options={
                        filterFields?.languages?.filter((tag) => !selectedOptions?.includes(tag.languageName || tag)) ||
                        []
                    }
                    filterSelectedOptions
                    className="languages-selector"
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            value={params.value?.languageName}
                            label="Languages"
                            placeholder={isCreatingProfile ? "Select" : "Languages"}
                        />
                    )}
                />
            );
        } else {
            return (
                <div className={Styles["languages-selector"]} data-testid="languages-tags">
                    {selectedOptions?.map((tag) => {
                        const found = filterFields?.languages?.find((x) => x.id === tag);
                        if (found)
                            return (
                                <Chip className={Styles["language-tag"]} label={found.languageName} key={found.id} />
                            );
                        return null;
                    })}
                </div>
            );
        }
    }
    if (state?.profile?.languages || isCreatingProfile || isEditing)
        return (
            <View id="languages-i-speak" className={!isCreatingProfile && "profile-section"}>
                {!isEditing && isCreatingProfile && <Text as="h2">Select all languages you speak</Text>}

                {!isCreatingProfile && (
                    <Text
                        sx={{ fontSize: 25 }}
                        data-testid="languages-i-speak"
                        className={`${Styles["profile-language-tags-head-text"]}`}>
                        Languages I speak
                    </Text>
                )}

                {!loading ? renderQuestionOrShowValues() : <CircularProgress />}

                <br />

                {!isEditing && isCreatingProfile && (
                    <View className="button-container">
                        <LoadingBtn
                            label="THAT'S IT"
                            loading={isLoading}
                            disabled={disabledValue}
                            handleOnClick={handleSaveValue}
                        />
                    </View>
                )}
            </View>
        );
    else return null;
}
