import React, { useEffect, useState } from "react";
import { Box, FormControl, InputLabel, MenuItem, Select, Slider, Tooltip } from "@mui/material";
import View from "@mui/material/Box";
import Text from "@mui/material/Typography";
import { ShouldRender } from "../../ShouldRender/ShouldRender";
import { ComponentSwitch } from "utils/functions";
import { InfoTwoTone } from "@mui/icons-material";
import { PopupConfirmation } from "components/PopupConfirmation";

const Questions = ({ textValue, changeObj, questions }) => {
    const Question = ({ question, changeObj, textValue }) => {
        return (
            <FormControl fullWidth sx={{ mt: 2 }} data-testid="game-related-question">
                <InputLabel id={`${question.id}-select`}>{question.title}</InputLabel>
                <Select
                    fullWidth
                    name={question.key}
                    id={`${question.id}-select`}
                    labelId={`${question.id}-select`}
                    value={textValue[question.key]}
                    label={question.title}
                    onChange={changeObj}>
                    {question.options.map((option) => {
                        return (
                            <MenuItem key={"SS" + option} id={"SS" + option} value={option}>
                                {option}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
        );
    };

    return (
        <Box className="edit-questions-container">
            {questions.map((question) => (
                <Question key={question.id} question={question} changeObj={changeObj} textValue={textValue} />
            ))}
        </Box>
    );
};
function QuestionTitle({ title, description, setStyleOfPlayModal, isMobile, isTablet }) {
    return (
        <Text className="slider-label">
            {title}
            {ComponentSwitch(
                // Case is mobile
                isMobile || isTablet,
                <InfoTwoTone
                    onClick={() => {
                        setStyleOfPlayModal((prev) => ({
                            ...prev,
                            open: true,
                            title: title,
                            description: description,
                        }));
                    }}
                />,
                // Default behavior
                <Tooltip placement="top-start" title={description}>
                    <InfoTwoTone />
                </Tooltip>,
            )}
        </Text>
    );
}

const SliderQuestion = ({ textValue, changeObj, question, marks, isMobile, isTablet, setStyleOfPlayModal }) => { 
    return (
        <View className="slider-row">
            <QuestionTitle
                title={question.title}
                description={question.description}
                setStyleOfPlayModal={setStyleOfPlayModal}
                marks={marks}
                isMobile={isMobile}
                isTablet={isTablet}
            />
            <Slider
                className="slider-slider"
                value={Number(textValue[question.key])}
                name={question.key}
                key={question.key}
                onChange={changeObj}
                marks={marks}
                min={0}
                max={4}
            />
        </View>
    );
};

const SliderQuestions = ({ textValue, changeObj, questions, marks, isMobile, isTablet, setStyleOfPlayModal }) => {
    return (
        <Box className="game-preferences-slider-container">
            {questions.map((question) => (
                <SliderQuestion
                    key={question.key}
                    description={question.description}
                    marks={marks}
                    textValue={textValue}
                    changeObj={changeObj}
                    question={question}
                    isMobile={isMobile}
                    isTablet={isTablet}
                    setStyleOfPlayModal={setStyleOfPlayModal}
                />
            ))}
        </Box>
    );
};

export const GamePreferencesEdit = ({ state, changeEditState, isMobile, isTablet }) => {
    const [thisLikedState, setThingsLikedState] = useState();
    const [styleOfPlayModal, setStyleOfPlayModal] = React.useState({ open: false, title: "", description: "" });


    const questions = [
        {
            id: "safety",
            title: "Importance of safety tools to me",
            key: "safetyTools",
            options: [
                "They are super important",
                "I keep lines of communication open",
                "I don't know what safety tools are",
                "I don't use safety tools",
            ],
        },
        {
            id: "lgbt",
            title: "LGBTQIA+ Friendly games?",
            key: "LGBTQ",
            options: ["Yes, I'm LGBTQIA+", "Yes", "I'm not sure.", "It's not a focus for us."],
        },
        {
            id: "char-death",
            title: "Am I ok with character death?",
            key: "characterDeath",
            options: ["No.", "Depends on the game.", "I embrace character death."],
        },
        /* {
            id: "rules",
            title: "Importance of rules",
            key: "ruleImportance",
            options: [
                "Very important",
                "Somewhat important",
                "Not so important",
                'Not at all important ("What rules?")',
            ],
        },
        {
            id: "profanity",
            title: "Am I ok with profanity:",
            key: "profanityLevel",
            options: [
                "We make pirates blush are our table (the most)",
                "F-bombs are f*ing cool",
                "The occasional swear",
                "It's all good (no preference)",
            ],
        }, */
    ];

    const hasState = () => {
        let newObj = {
            roleplaying: 2,
            combat: 2,
            exploring: 2,
            optimizing_mechanics: 2,
            social_intrigue: 2,
            puzzles: 2,
            player_driven_story: 2,
        };

        if (state.profile.thingsLikedTTRPG) {
            const thingsLikedTTRPG = state.profile.thingsLikedTTRPG.map(Number);
            newObj = {
                roleplaying: thingsLikedTTRPG[0],
                combat: thingsLikedTTRPG[1],
                exploring: thingsLikedTTRPG[2],
                optimizing_mechanics: thingsLikedTTRPG[3],
                social_intrigue: thingsLikedTTRPG[4],
                puzzles: thingsLikedTTRPG[5],
                player_driven_story: thingsLikedTTRPG[6],
            };
            setThingsLikedState({ thingsLikedTTRPG });
        }

        // Fullfill the questions
        questions.forEach((question) => {
            const valueOnProfile = state.profile[question.key];

            if (valueOnProfile) {
                newObj[question.key] = valueOnProfile;
            }
        });

        return newObj;
    };

    const [textValue, setTextValue] = useState(hasState);

    const marks = [{ value: 0 }, { value: 1 }, { value: 2 }, { value: 3 }, { value: 4 }];

    const sliderQuestions = [
        {
            title: "Roleplaying",
            key: "roleplaying",
            description:
                "The roleplaying style is about stepping into another world, embracing a character's identity, and collaboratively creating a narrative that is shaped by the choices and actions of the participants. It's a dynamic and engaging form of storytelling that allows for a high degree of personal and group creativity.",
        },
        {
            title: "Combat",
            key: "combat",
            description:
                "The combat style involves turn-based actions, dice rolls to determine success, and resource management. Characters have various abilities and actions, and combat may be grid-based or in the mind's theater. Environmental factors and teamwork play important roles. The GM's narration and storytelling contribute to the immersive experience, and the ultimate goal is to advance the campaign's narrative through strategic and role-playing interactions.",
        },
        {
            title: "Exploring",
            key: "exploring",
            description:
                " Exploring style involves characters investigating the game world. It can be both structured or open-ended. Players may use skills and abilities to uncover secrets, solve puzzles, etc. The GM's descriptions and storytelling are key to creating an immersive exploration experience. The goal is to reveal the game world's depth, uncover plot details, and advance the narrative. ",
        },
        {
            title: "Optimizing Mechanics",
            key: "optimizing_mechanics",
            description:
                "The optimizing/mechanics style focuses on maximizing character efficiency and effectiveness. Players delve into rules and character build details, seeking the best combinations of abilities and equipment. They often aim to min-max character stats for combat and other challenges. This style emphasizes game mechanics over narrative immersion, and success is measured by character optimization and overcoming in-game challenges.",
        },
        {
            title: "Social Intrigue",
            key: "social_intrigue",
            description:
                "The social intrigue style centers on character interactions, diplomacy, and politics. Players use their characters' social skills, wits, and alliances to navigate intricate plots, negotiate, and uncover secrets. The game master's storytelling and role-playing are crucial for creating a rich and immersive social landscape. Success in this style is often defined by achieving personal or factional goals, manipulating alliances, and unraveling complex narratives.",
        },
        {
            title: "Puzzles",
            key: "puzzles",
            description:
                "The puzzle style revolves around solving puzzles and riddles within the game world. Players use problem-solving skills and creativity to overcome challenges. The game master designs puzzles that require logic, lateral thinking, and clever solutions. Success is measured by unraveling mysteries, progressing the story, and achieving in-game goals by solving puzzles.",
        },
        {
            title: "Player Driven Story",
            key: "player_driven_story",
            description:
                "Player-driven story style puts the narrative in the hands of the players. They actively shape the plot through their character's choices and actions. The game master provides a flexible world and adapts to player decisions. Success is defined by the depth of character development and the richness of the emergent story, where the players' choices have a profound impact on the campaign's direction.",
        },
    ];

    const changeObj = (e) => {
        e.preventDefault();
        e.stopPropagation();

        setTextValue((textValue) => ({
            ...textValue,
            [e.target.name]: e.target.value,
        }));

        if (
            ![
                "roleplaying",
                "combat",
                "exploring",
                "optimizing_mechanics",
                "social_intrigue",
                "puzzles",
                "player_driven_story",
            ].includes(e.target.name)
        ) {
            changeEditState({ [e.target.name]: e.target.value });
        }
    };

    useEffect(() => {
        const thingsLiked = [];

        thingsLiked.push(
            Number(textValue.roleplaying),
            Number(textValue.combat),
            Number(textValue.exploring),
            Number(textValue.optimizing_mechanics),
            Number(textValue.social_intrigue),
            Number(textValue.puzzles),
            Number(textValue.player_driven_story),
        );

        if (!(JSON.stringify(thisLikedState) === JSON.stringify({ thingsLikedTTRPG: thingsLiked }))) {
            changeEditState({ thingsLikedTTRPG: thingsLiked });
        }
    }, [textValue]);

    return (
        <ShouldRender>
            <Box>
                <PopupConfirmation
                    open={styleOfPlayModal.open}
                    message={styleOfPlayModal.description}
                    setOpen={(bool) => setStyleOfPlayModal({ ...styleOfPlayModal, open: bool })}
                    btnReject="Close"
                    disableBtnAccept
                />
                <Text as="h2" className="profile-component-title">
                    Games Preferences
                </Text>
                <Text className="game-preferences-subtitle edit">Style of play</Text>
                <SliderQuestions
                    marks={marks}
                    textValue={textValue}
                    changeObj={changeObj}
                    questions={sliderQuestions}
                    isMobile={isMobile}
                    isTablet={isTablet}
                    setStyleOfPlayModal={setStyleOfPlayModal}
                />
                <Questions questions={questions} textValue={textValue} changeObj={changeObj} />
            </Box>
        </ShouldRender>
    );
};
export default GamePreferencesEdit;
