import { Flex, View } from "@aws-amplify/ui-react";
import LeftMenu from "components/LeftMenu";
import { RightCards } from "@components/RightCards/RightCards";
import { useGlobalContext } from "context/AppContext";
import { useBreakpoint } from "hooks/useBreakpoint";
import { useProfileCheck } from "hooks/useProfileCheck";
import { useSignOut } from "hooks/useSignOut";
import React, { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { ChatRightWrapper } from "@components/InboxComponents/ChatRightWrapper/ChatRightWrapper";
import {useBadges} from "hooks/useBadges";
import Interstitial from "components/Interstitial";
import {useAsyncFilterFields} from "hooks/useAsyncFilterFields";

export const PageWrapper = ({
    overrideCentering,
    hideMenu,
    children,
    showCardsForPage = null,
    isMessagePage = false,
}) => {
    const { userHasProfile } = useProfileCheck();
    const { leftMenuSignOut } = useSignOut();
    const { isLaptop } = useBreakpoint();

    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const [activeBtn, setActiveBtn] = useState(location.pathname);
    const { isDesktop } = useGlobalContext();

    // Execute custom hook for interstitial displaying
    const { openInterstitial} = useBadges()

    // Execute custom hook for managing async filter data
    useAsyncFilterFields();

    useEffect(() => {
        setActiveBtn(location.pathname);

        if (
            !location.pathname.includes("/profile/") &&
            !!searchParams.get("profileImage") &&
            !!searchParams.get("matchScoreNumber")
        ) {
            searchParams.delete("profileImage");
            searchParams.delete("matchScoreNumber");
            setSearchParams(searchParams);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    return (
        <>
            <Flex height={"inherit"} gap={"0"} justifyContent={isLaptop || overrideCentering ? "" : "center"}>
                <LeftMenu
                    userHasProfile={userHasProfile}
                    leftMenuSignOut={leftMenuSignOut}
                    location={location}
                    activeBtn={activeBtn}
                    setActiveBtn={setActiveBtn}
                    overrideCentering={overrideCentering}
                    hideMenu={hideMenu}
                />
                <View className="right-wrapper" width={hideMenu && "100%"}>
                    {children}
                </View>
                {showCardsForPage && isDesktop && <RightCards page={showCardsForPage} />}
                {isMessagePage && isDesktop && <ChatRightWrapper />}
            </Flex>
            <Interstitial open={openInterstitial} />
        </>
    );
};
