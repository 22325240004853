import { AsyncFilterFieldsContext } from "context/AsyncFilterFieldsContext";
import { Autocomplete, Chip, TextField } from "@mui/material";

import Styles from "../../components/Profile/components/ProfileBody/IdentityTags/Styles.module.css";

import View from "@mui/material/Box";
import Text from "@mui/material/Typography";
import LoadingBtn from "components/LoadingBtn/LoadingBtn";

import { useState, useContext } from "react";
import { ShouldRender } from "components/Profile/components/ShouldRender/ShouldRender";
import { isFilled } from "utils/functions";

const AutoCompleteComponent = ({ selectedOptions, handleChange, identityTagsOptions, placeholder }) => {
    const props = {
        value: selectedOptions,
        multiple: true,
        getOptionLabel: (tag) => tag.name || tag,
        onChange: (event, newValue) => handleChange(newValue),
        options: identityTagsOptions,
        filterSelectedOptions: true,
        className: "identity-tags-selector",
    };
    return (
        <Autocomplete
            {...props}
            data-testid={"identity-tags"}
            renderInput={(params) => (
                <TextField {...params} value={params.value} label={"Identity Tags"} placeholder={placeholder} />
            )}
        />
    );
};

const ViewingProfileComponent = ({ state }) => (
    <div className={Styles["identity-tags-selector"]} data-testid="identity-tags">
        {state?.profile?.identityTags?.map((tag) => {
            return <Chip className={Styles["identity-tag"]} label={tag} key={tag} />;
        })}
    </div>
);

export default function ProfileIdentityTags({
    state,
    editState,
    isEditing = false,
    changeEditState,
    handleEditProfile,
    isCreatingProfile = true,
}) {
    const [selectedOptions, setSelectedOptions] = useState(state?.identityTags || state?.profile?.identityTags || []);
    // const [disabledValue, setDisabledValue] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { filterFields, loading } = useContext(AsyncFilterFieldsContext);

    function handleChange(value) {
        setSelectedOptions(value);

        if (isEditing) {
            changeEditState({ identityTags: value.map((x) => x?.name || x) });
        }
    }

    async function handleSaveValue() {
        const updateData = selectedOptions.map((x) => x?.name || x);

        setIsLoading(true);
        await handleEditProfile({ identityTags: updateData });
        setIsLoading(false);
    }

    const identityTagsOptions = filterFields?.identityTags?.filter(
        (tag) => !selectedOptions?.includes(tag.name || tag) && tag?.enabled !== false,
    );

    function RenderQuestionOrShowValues() {
        if (isCreatingProfile)
            return (
                <AutoCompleteComponent
                    selectedOptions={selectedOptions}
                    handleChange={handleChange}
                    identityTagsOptions={identityTagsOptions}
                    placeholder="Select"
                />
            );
        if (isEditing)
            return (
                <AutoCompleteComponent
                    selectedOptions={selectedOptions}
                    handleChange={handleChange}
                    identityTagsOptions={identityTagsOptions}
                    placeholder="Identity Tags"
                />
            );
        return <ViewingProfileComponent state={state} />;
    }

    return (
        <ShouldRender returnNull condition={isFilled(state?.profile?.identityTags) || isCreatingProfile || isEditing}>
            <View id="identity-tags" className={!isCreatingProfile && "profile-section"}>
                {!isEditing && isCreatingProfile && <Text as="h2">Would you like to choose your identity tags?</Text>}
                {!isCreatingProfile && (
                    <Text className={`${Styles["profile-identity-tags-head-text"]} `} as="h2">
                        Identity Tags
                    </Text>
                )}

                <ShouldRender disableErrorBoundary loading={loading && filterFields.identityTags}>
                    <RenderQuestionOrShowValues />
                </ShouldRender>

                <br />
                {!isEditing && isCreatingProfile && (
                    <View className="button-container identity-tags-submit">
                        <LoadingBtn
                            label="THAT'S IT"
                            loading={isLoading}
                            // disabled={disabledValue}
                            handleOnClick={handleSaveValue}
                        />
                    </View>
                )}
            </View>
        </ShouldRender>
    );
}
