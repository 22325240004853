import "./Chat.scss";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useMessages } from "@pages/Inbox/hooks/useMessages";
import { formatTimestamp } from "@utils/dateTimeFormatter";
import { Box, Button, CircularProgress, IconButton, Typography } from "@mui/material";
import { useMessagesContext } from "@context/MessagesContext";
import { useProfileEdit } from "hooks/useProfileEdit";
import { Send } from "@mui/icons-material";
import useAutosizeTextarea from "hooks/useAutosizeTextarea";
import { useUser } from "@pages/Inbox/hooks/useUser";
import { useGlobalContext } from "context/AppContext";
import { useBrowserAgent } from "@hooks/useBrowserAgent";
import { profileProvider } from "services/profileProvider";
import { useScrollBlockerV2 } from "@hooks/Messages.hooks";
import { MessagesBlocker } from "../MessagesBlocker/MessagesBlocker";
import { NoMessages } from "../NoMessages/NoMessages";
import Markdown from "marked-react";

export const Chat = () => {
    const { loadingMessage, messages, sendMessage, onChange, updateInbox, getPersonalProfile } = useMessages();
    const { profiles, currentMessage, setProfiles, messageDisabled } = useMessagesContext();
    const { isLoadingSave, enableMessaging } = useProfileEdit({ getPersonalProfile });
    const { blockedUser, setBlockedUser } = useUser();
    const { personalProfile } = useGlobalContext();
    const { safariAgent } = useBrowserAgent();
    const { onFocusInput, onBlurInput, firstRef, keyboardOpen } = useScrollBlockerV2({ safariAgent });
    const [receiverIsDeleted, setReceiverIsDeleted] = useState(false);

    const containerRef = useRef<HTMLDivElement>(null);
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    useAutosizeTextarea(textareaRef.current, currentMessage);

    const isVersion16 = useMemo(() => {
        return navigator?.userAgent.includes("iPhone OS 16");
    }, []);

    const recieverMessageDisabled = useMemo(() => {
        return profiles?.public?.messageDisabled?.[0];
    }, [profiles?.public?.messageDisabled]);

    const hasNoMessages = useMemo(() => {
        return messages.length === 0;
    }, [messages.length]);

    const sortedMessages = useMemo(() => {
        return messages.sort((messageA, messageB) => Number(messageA.date) - Number(messageB.date));
    }, [messages]);

    const getText = () => {
        if (receiverIsDeleted) {
            return "This user was deleted.";
        }

        if (messageDisabled) {
            return "Messaging is disabled.";
        }

        if (recieverMessageDisabled) {
            return "This user disabled messaging.";
        }

        return "";
    };

    const handleEnter = (event: any) => {
        if (!event.shiftKey) {
            if (event.keyCode === 13) {
                handleSendMessage(event);
                return;
            }
        }
    };

    const handleSendMessage = (event: any) => {
        event.preventDefault();
        event.stopPropagation();

        if (!blockedUser && currentMessage && !messageDisabled && !recieverMessageDisabled) {
            sendMessage();
            updateInbox();
        }
    };

    const handleEnableMessaging = async () => {
        await enableMessaging({});
    };

    useEffect(() => {
        const { current } = containerRef;
        if (current) {
            const height = current.scrollHeight;
            current.scrollTop = height;
        }
    }, [messages.length]);

    useEffect(() => {
        const didReload = sessionStorage.getItem("didReload");
        if (blockedUser) {
            sessionStorage.setItem("blockedUser", "blocked");
        }
        if (isVersion16 && didReload !== "yes") {
            window.location.reload();
            sessionStorage.setItem("didReload", "yes");
        }

        return () => {
            sessionStorage.setItem("didReload", "no");
            sessionStorage.removeItem("blockedUser");
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        (async () => {
            try {
                if (personalProfile) {
                    const pathname = window.location.pathname;
                    const _blockedUser = sessionStorage.getItem("blockedUser");
                    setBlockedUser(_blockedUser === "blocked");
                    const publicId = pathname
                        .split("/messages/" + personalProfile.id)
                        .filter((item) => item)
                        .toString();
                    const publicProfileData = await profileProvider.getProfile(publicId);

                    if (publicProfileData) {
                        setProfiles({
                            private: personalProfile,
                            public: publicProfileData.data,
                        });
                    } else {
                        setReceiverIsDeleted(true);
                    }
                }
            } catch (error) {
                console.log("error", error);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [personalProfile]);

    return (
        <Container fluid className="chat-container d-flex h-100 flex-column p-0 overflow-hidden">
            <MessagesBlocker>
                {hasNoMessages && (
                    <Row className="h-100 align-items-center no-messages-row">
                        <NoMessages message={loadingMessage} />
                    </Row>
                )}

                {!hasNoMessages && (
                    <>
                        <Row className="h-100 m-0 overflow-auto messages-row" ref={containerRef}>
                            <Box className="message-overlay"></Box>
                            <Col ref={firstRef} className="p-4 row-gap-3 d-flex flex-column justify-content-end">
                                {sortedMessages
                                    .filter((item, index) => {
                                        const lastIndex = sortedMessages.length - 1;
                                        const secondLastIndex = sortedMessages.length - 2;
                                        if (keyboardOpen) {
                                            return index === lastIndex || index === secondLastIndex;
                                        }

                                        return item;
                                    })
                                    .map((message, index) => {
                                        const dateObj = new Date(Number(message.date));
                                        const hoursDiff = Math.abs(new Date().valueOf() - dateObj.valueOf()) / 36e5;
                                        const date = formatTimestamp(hoursDiff, dateObj, undefined);

                                        const isFrom = profiles?.private?.id !== message.from;

                                        return (
                                            <Row
                                                style={{ wordBreak: "break-all" }}
                                                className="m-0"
                                                key={`message-${index}`}>
                                                <Col
                                                    className={`p-0 d-flex flex-column align-items-${
                                                        isFrom ? "start" : "end"
                                                    }`}>
                                                    {message.system && (
                                                        <Row className="m-0 w-100">
                                                            <div className="automated-message-wrapper">
                                                                <img
                                                                    height={24}
                                                                    width={24}
                                                                    src="/bell.svg"
                                                                    alt="Automated Message Bell Icon"
                                                                />
                                                                <Typography className="automated-message">
                                                                    This is an automated system message
                                                                </Typography>
                                                            </div>
                                                        </Row>
                                                    )}
                                                    <Row
                                                        className={`m-0 ${isFrom ? "from" : "sent"}-baloon ${
                                                            message.system && "automated"
                                                        }`}>
                                                        <Typography className="p-0">
                                                            {message.body &&
                                                                message.body.split(/\r?\n/).map((msg, i) => (
                                                                    <div key={`msg-item-${i}`}>
                                                                        <Markdown>{msg}</Markdown>
                                                                    </div>
                                                                ))}
                                                        </Typography>
                                                    </Row>
                                                    <Row className="m-0">
                                                        <Typography className="message-date p-0">{date}</Typography>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        );
                                    })}
                            </Col>
                        </Row>
                    </>
                )}

                <Row className="m-0 chat-input-row d-flex column-gap-2">
                    <Col className="p-0 d-flex flex-column justify-content-center">
                        {messageDisabled || recieverMessageDisabled || receiverIsDeleted ? (
                            <Box className="pseudo-textarea d-flex align-items-center flex-wrap">
                                <Typography>{getText()}</Typography>
                                {messageDisabled && (
                                    <Button
                                        className="enable-messaging-button"
                                        onClick={handleEnableMessaging}
                                        disabled={isLoadingSave}>
                                        Click to enable.
                                    </Button>
                                )}
                            </Box>
                        ) : (
                            <textarea
                                className="w-100 chat-input"
                                onFocus={onFocusInput}
                                onBlur={onBlurInput}
                                ref={textareaRef}
                                disabled={blockedUser}
                                onKeyDown={handleEnter}
                                value={currentMessage}
                                onChange={onChange}
                                placeholder="Start a new message"
                            />
                        )}
                    </Col>
                    <Col className="col-auto p-0 d-flex flex-column justify-content-center send-button-col align-items-center">
                        {isLoadingSave ? (
                            <CircularProgress color="inherit" size={30} />
                        ) : (
                            <IconButton
                                disabled={messageDisabled || recieverMessageDisabled || receiverIsDeleted}
                                onClick={handleSendMessage}
                                onMouseDown={(e) => {
                                    e.preventDefault();
                                }}>
                                <Send />
                            </IconButton>
                        )}
                    </Col>
                </Row>
            </MessagesBlocker>
        </Container>
    );
};
