import { typesOfSort } from "hooks/useSort";
import { clearFalsyFromObject2, filterSearchParams } from "./functions";
import { defaultFilters } from "context/FilterContext";

export default function getDeeplink({
    setSortingOption,
    setFilters,
    filters,
    firstFilters,
    setHasFilter,
    setUsernameVal,
    setListOrder,
    setSearchParams,
    setCurrentFilters,
    setPreviousFilters,
    currentFilters,
    publicLocation = false,
    publicGame = false,
}) {
    if (!setSortingOption) {
        console.error("Missing params in getDeeplink function");
        setHasFilter(false);
        setUsernameVal([]);
        setFilters(defaultFilters);
        return;
    }

    const filteredSearchParams =
        clearFalsyFromObject2(
            filterSearchParams({
                ...filters,
                ...firstFilters,
            }),
        ) || {};
    const selectedSort = typesOfSort.find((type) => type.urlParam === filteredSearchParams?.sort); // Deeplink for sort

    /**
     * Defines the query filters to be requested
     */
    setPreviousFilters({ ...currentFilters });
    setCurrentFilters(filteredSearchParams);

    /**
     * Defines filter values for Filterpopup
     */
    setFilters(filteredSearchParams);

    /**
     * Ignore sorting when determining if filters are active
     */
    const filteredSearchParamsWithoutSort = { ...filteredSearchParams };
    delete filteredSearchParamsWithoutSort["sort"];
    if (Object.keys(filteredSearchParamsWithoutSort).length > 0) setHasFilter(true);

    if (selectedSort) {
        setSortingOption(selectedSort.displayName);
        setListOrder(selectedSort.displayName);
    }

    /**
     * remove sort= empty query string
     */
    if (!filteredSearchParams.sort) {
        delete filteredSearchParams.sort;
    }

    const params = new URLSearchParams(filteredSearchParams);
    if (!publicLocation && !publicGame) {
        setSearchParams(params.toString());
    }
}
