import { View, Text } from "@aws-amplify/ui-react";
import "./OptionalQuestions.css";
import React from "react";
import { optional_questions } from "@utils/constants";
import { FormControl, InputLabel, Select, MenuItem, TextField } from "@mui/material";

/**
 * Renders optional questions based on the state and edit state.
 *
 * @param {Object} state - The user data saved in the database.
 * @param {boolean} isEditing - Indicates whether the questions are being edited.
 * @param {function} changeEditState - The function to change the edit state.
 * @param {Object} editState - The changes made to state.
 * @return {ReactElement|null} The rendered optional questions or null.
 */
const OptionalQuestions = ({ state, isEditing = false, changeEditState, editState, renderQuestions }) => {
    /**
     * Renders the elements based on optional questions and editing mode.
     *
     * @param {Array} optionalQuestions - an array of optional questions
     * @param {boolean} isEditing - indicates whether the elements are being rendered in editing mode
     * @return {Array} an array of rendered elements
     */
    const renderElements = (optionalQuestions, isEditing) => {
        if (!isEditing) {
            return renderQuestions([...optional_questions], state);
        }

        return optionalQuestions
            .filter((item) => (isEditing ? item.editable : true))
            .map((questionObject) => {
                const { title, key, type } = questionObject;
                const questionResponse = state.profile[key];
                const responseType = typeof questionResponse;

                const onSelectChange = (event) => {
                    const value = event.target.value;

                    if (type === "number") {
                        const regexp = /^\d+$/;

                        if (regexp.test(parseInt(value))) {
                            changeEditState({ [key]: parseInt(value) });
                            return;
                        }

                        if (value === "") {
                            changeEditState?.({ [key]: "" });
                            return;
                        }
                    }

                    changeEditState?.({ [key]: value });
                };

                const generateMenuItems = () => {
                    return questionObject.answers?.map((option, index) => (
                        <MenuItem key={`${key}-option-${index}`} value={option}>
                            {option}
                        </MenuItem>
                    ));
                };

                const getInputByType = () => {
                    let numberValue = state.profile?.[key]?.[0];
                    if (numberValue !== undefined) numberValue = parseInt(numberValue);

                    let editStateValue = editState?.[key];
                    if (editStateValue === "") editStateValue = undefined;

                    const numberComponent = () => (
                        <TextField
                            type="number"
                            fullWidth
                            data-testid={`profile-${key}-input`}
                            onChange={onSelectChange}
                            value={editState?.[key] !== undefined ? editStateValue : numberValue}
                            id={`${key}-picker`}
                            label={title}
                            variant="outlined"
                            inputProps={{ maxLength: 20 }}
                        />
                    );

                    const textComponent = () => (
                        <>
                            <InputLabel id={`${key}-picker`} {...{ "data-testid": `profile-${key}-select` }}>
                                {title}
                            </InputLabel>
                            <Select
                                labelId={`${key}-picker`}
                                id={`${key}-picker`}
                                value={editState?.[key] || state.profile?.[key]?.[0]}
                                label={title}
                                inputProps={{ "data-testid": `${key}-picker-input` }}
                                onChange={(e) => onSelectChange(e)}>
                                {generateMenuItems()}
                            </Select>
                        </>
                    );

                    switch (type) {
                        case "number":
                            return numberComponent();
                        case "string":
                            return textComponent();
                        default:
                            return textComponent();
                    }
                };

                if (isEditing) {
                    return (
                        <View
                            className="form-container edit-optional-question"
                            key={key}
                            data-testid={`profile-${key}-select`}>
                            <FormControl fullWidth>{getInputByType()}</FormControl>
                        </View>
                    );
                }

                if (responseType === "string") {
                    return (
                        <>
                            <Text key={key} as="h3" className="profile-questions">
                                {title}
                                <br />
                                <strong>{questionResponse}</strong>
                            </Text>
                        </>
                    );
                } else if (responseType === "object") {
                    return (
                        <View key={key}>
                            <Text as="h3" className="profile-questions">
                                {title}
                                {questionResponse.map((response) => {
                                    const realResponse = response || response === 0 ? response : "No Response";
                                    return (
                                        <React.Fragment key={response}>
                                            <br />
                                            <strong key={response}>{realResponse}</strong>
                                        </React.Fragment>
                                    );
                                })}
                            </Text>
                        </View>
                    );
                } else {
                    return null;
                }
            });
    };

    if (optional_questions.filter((question) => state.profile?.[question.key]).length === 0 && !isEditing) return null;
    return (
        <View
            id="additional-questions"
            data-testid="optional-questions"
            className="optional-questions-container profile-component">
            <Text as="h2" className="optional-questions-head-text">
                Additional Questions
            </Text>
            {renderElements(optional_questions, isEditing)}
        </View>
    );
};

export default OptionalQuestions;
