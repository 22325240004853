import React from "react";
import { AmplifyProvider } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css"; // default theme
import { Auth } from "aws-amplify";
import { Helmet } from "react-helmet";
import AppRoutes from "Route";
import { checkEnvironment } from "./utils/functions";
import { AwsRum } from "aws-rum-web";

function startAwsRum(env) {
    const envState = {
        development: {
            identityPoolId: "us-west-2:715678fe-a847-4430-84cc-bc39dc48528a",
            guestRoleArn: "arn:aws:iam::578589379103:role/development-RUM-us-west-2-578589379103-Guest",
            appId: "aa40c3f0-4ba9-4f6c-8122-553ca1b4afe7",
        },
        development2: {
            identityPoolId: "us-west-2:76603957-fd8b-43ff-98fa-1cead06429ed",
            guestRoleArn: "arn:aws:iam::578589379103:role/development2-RUM-us-west-2-578589379103-Guest",
            appId: "d2462b90-3ca2-4bfa-a194-12c99fd997b8",
        },
        staging: {
            identityPoolId: "us-west-2:027f66c9-600a-4989-829d-d307ea5d48c1",
            guestRoleArn: "arn:aws:iam::578589379103:role/staging-RUM-us-west-2-578589379103-Guest",
            appId: "482c28b6-41ed-44f7-bc38-15e1caf147e8",
        },
        production: {
            identityPoolId: "us-west-2:de123150-529d-4514-8eec-5cd728a19d6f",
            guestRoleArn: "arn:aws:iam::578589379103:role/production-RUM-us-west-2-578589379103-Guest",
            appId: "f6bcf1a2-f8dc-4874-b996-2cd2f8d470a9",
        },
    };

    try {
        const config = {
            sessionSampleRate: 1,
            guestRoleArn: envState[env].guestRoleArn,
            identityPoolId: envState[env].identityPoolId,
            endpoint: "https://dataplane.rum.us-west-2.amazonaws.com",
            telemetries: ["http", "errors", "performance"],
            allowCookies: true,
            enableXRay: true,
        };
        const APPLICATION_ID = envState[env].appId;
        const APPLICATION_VERSION = "1.0.0";
        const APPLICATION_REGION = "us-west-2";

        const awsRum = new AwsRum(APPLICATION_ID, APPLICATION_VERSION, APPLICATION_REGION, config);
    } catch (errors) {
        // ignore errors
    }
}
startAwsRum(checkEnvironment());

switch (checkEnvironment()) {
    case "development2": {
        console.log("Using development 2 config...");
        Auth.configure({
            region: "us-west-2",
            userPoolId: "us-west-2_zBe4ABgQ0",
            userPoolWebClientId: "23216tn10cbp09i7r1ra0ol98e",
            authenticationFlowType: "USER_PASSWORD_AUTH",
        });
        break;
    }
    case "development": {
        console.log("Using development config...");
        Auth.configure({
            region: "us-west-2",
            userPoolId: "us-west-2_illLvmgx0",
            userPoolWebClientId: "19b4fftqb9jf7uqpolvvt6f561",
            authenticationFlowType: "USER_PASSWORD_AUTH",
        });
        break;
    }
    case "staging": {
        console.log("Using staging config...");
        Auth.configure({
            region: "us-west-2",
            userPoolId: "us-west-2_nqWoySxcF",
            userPoolWebClientId: "6sbo3in6se9vavtv80dsq2c824",
            authenticationFlowType: "USER_PASSWORD_AUTH",
        });
        break;
    }
    case "production": {
        //console.log('Using production config...')
        Auth.configure({
            region: "us-west-2",
            userPoolId: "us-west-2_I8TleLpWb",
            userPoolWebClientId: "3qiadu5q7uuur0nfj0otdk7grb",
            authenticationFlowType: "USER_PASSWORD_AUTH",
        });
        break;
    }
    default:
        Auth.configure({
            region: "us-west-2",
            userPoolId: "us-west-2_I8TleLpWb",
            userPoolWebClientId: "3qiadu5q7uuur0nfj0otdk7grb",
            authenticationFlowType: "USER_PASSWORD_AUTH",
        });
        break;
}

const theme = {
    name: "rpg-theme",
    tokens: {
        colors: {
            font: {
                primary: { value: "white" },
            },
        },
        components: {
            divider: {
                borderColor: { value: "#BF1304" },
                padding: { value: "10px" },
            },
        },
    },
};

const AppContainer = () => {
    return (
        <AmplifyProvider className={"amplify-container"} theme={theme}>
            <Helmet>
                <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
                <meta
                    name="description"
                    content="RPGMatch: a table top roleplaying game services connecting TTRPG players with other like-minded players."
                />
                <title>RPGMatch</title>
                {/* <!-- Global site tag (gtag.js) - Google Analytics --> */}
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-1Y87K5VE1E"></script>
                <script>
                    {`
                        window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());
                        gtag('config', 'G-1Y87K5VE1E');    
                    `}
                </script>
                <script>{`
(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-ND86RGVT');`}</script>
            </Helmet>
            <AppRoutes />
        </AmplifyProvider>
    );
};

export default AppContainer;
