import "./MessageOrEditBtn.scss";

import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Tooltip } from "@mui/material";
import { Email, Send } from "@mui/icons-material";

import EditComponent from "../EditComponent/EditComponent";

import { PopupConfirmation } from "components/PopupConfirmation";
import { useMessagesContext } from "@context/MessagesContext";
import { ApplyButton, SecondaryApplyButton } from "@components/ApplyButton";
import { InviteToGroupDialog } from "@components/Groups/GroupDialogs";

import useGroupInvite from "@hooks/useGroupInvite";
import { AsyncFilterFieldsContext } from "context/AsyncFilterFieldsContext";
import { ShouldRender } from "../../ShouldRender/ShouldRender";
import { storage } from "@services/sessionStorage";
/**
 *
 * allowSendMessage, redirectToChat, isEditing, onClick
 * added on click to make this component testable
 */
const MessageOrEditBtn = ({
    allowSendMessage,
    disabled,
    redirectToChat,
    isEditing,
    onClick,
    isPublicProfile,
    isLoggedIn,
    setLoginPopup,
    isSmallScreen,
    messageDisabled,
    profile,
}) => {
    /* States and Hooks */
    const navigate = useNavigate();

    const [openMobileTooltip, setOpenMobileTooltip] = useState(false);

    const { setReturnInbox } = useMessagesContext();
    const { loading: loadingCMS } = useContext(AsyncFilterFieldsContext);

    const {
        loading: loadingGroup,
        viewerGroup,
        userIsMember,
        openInviteDialog,
        setOpenInviteDialog,
    } = useGroupInvite({ user: profile });

    /* Functions */
    const onClickOpenTooltip = () => {
        if (isSmallScreen) {
            setOpenMobileTooltip(true);
        }
    };
    const navigateToMessage = () => {
        if (messageDisabled) {
            onClickOpenTooltip();
        } else {
            if (!disabled) {
                setReturnInbox(false);
                redirectToChat();
            }
            if (!isLoggedIn) {
                setLoginPopup?.(true);
            }
        }
    };

    /* Render */
    const userWasInvited = !!storage.sessionStorage.invitedUsers.get().find((id) => id === profile.id);

    console.table({ loadingCMS, loadingGroup, viewerGroup });

    if (allowSendMessage) {
        return (
            <>
                <InviteToGroupDialog
                    open={openInviteDialog}
                    setOpen={setOpenInviteDialog}
                    group={viewerGroup}
                    profile={profile}
                />
                <div className="message-or-edit">
                    <Tooltip
                        disableHoverListener={!disabled || isSmallScreen}
                        placement="top-end"
                        title={"Alas, you cannot message this user! They are taking a rest from their adventures."}>
                        <span tabIndex={0} role="button">
                            <ApplyButton
                                icon={<Email fontSize="small" sx={{ opacity: 0.7 }} />}
                                data-testid="send-message-button"
                                customClass={`message-button ${disabled ? "disabled" : ""} ${
                                    isPublicProfile ? "compact" : ""
                                }`}
                                name="message"
                                disabled={disabled}
                                sx={{
                                    height: "2.5rem",
                                    padding: "8px 16px",
                                    marginTop: "13.56rem",
                                }}
                                onClick={navigateToMessage}>
                                message
                            </ApplyButton>
                        </span>
                    </Tooltip>
                    <ShouldRender
                        returnNull
                        condition={
                            Boolean(isLoggedIn) &&
                            !userIsMember &&
                            !userWasInvited &&
                            (() => (Boolean(viewerGroup) && !loadingGroup) || (Boolean(!viewerGroup) && loadingGroup))()
                        }>
                        <SecondaryApplyButton
                            customClass="invite-button"
                            loading={loadingGroup || loadingCMS}
                            disabled={!loadingGroup && !Boolean(viewerGroup)}
                            icon={<Send fontSize="small" sx={{ opacity: 0.7 }} />}
                            onClick={() => setOpenInviteDialog(true)}>
                            Invite to my group
                        </SecondaryApplyButton>
                    </ShouldRender>
                </div>
                {process.env.NODE_ENV !== "test" && (
                    <PopupConfirmation
                        open={openMobileTooltip}
                        message={"Alas, you cannot message this user! They are taking a rest from their adventures."}
                        setOpen={setOpenMobileTooltip}
                        btnReject="Close"
                        disableBtnAccept
                    />
                )}
            </>
        );
    }
    return <EditComponent isEditing={isEditing} onClick={onClick ? onClick : () => navigate("/edit-profile")} />;
};

export default MessageOrEditBtn;
