import { Text, View } from "@aws-amplify/ui-react";
import { CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { profileProvider } from "@services/profileProvider";
import { checkLoggedIn, paramsToObject, saveRedirect, validateText } from "@utils/functions";

import Profile from "components/Profile/Profile";

import { setProfile } from "utils/setProfile";
import { useGlobalContext } from "context/AppContext";
import { useBreakpoint } from "hooks/useBreakpoint";
import { LoginBanner } from "@components/LoginBanner/LoginBanner";

const PublicProfile = (props) => {
    const [allowSendMessage, setAllowSendMessage] = useState(false);
    const [isPublicProfile, setPublicProfile] = useState(true);
    const { isLoggedIn, personalProfile, publicProfile, setPublicProfile: _setPublicProfile } = useGlobalContext();
    const state = publicProfile;
    const setState = (profile) => {
        _setPublicProfile(profile);
    };
    const { isMobile } = useBreakpoint();
    const { vanityUrl } = useParams();
    const [isFavorited, setIsFavorited] = useState(false);
    const [showLoginBanner, setShowLoginBanner] = useState(false);

    const [id, setId] = useState(null);

    const [searchParams] = useSearchParams();

    const { matchscore } = paramsToObject(searchParams.entries());

    useEffect(() => {
        if (state) {
            setState(null);
        }
        (async () => {
            try {
                const checkIfLogged = await checkLoggedIn();
                if ((!checkIfLogged && !personalProfile) || personalProfile) {
                    document.title = props.title;
                    setAllowSendMessage(personalProfile?.vanityUrl !== vanityUrl);
                    setPublicProfile(personalProfile?.vanityUrl !== vanityUrl);
                    const currentProfile = await profileProvider.getProfileByVanityURLAsync(vanityUrl);

                    setId(currentProfile?.data?.id?.replaceAll?.("-", ""));

                    if (currentProfile && !currentProfile?.data?.matchScore) {
                        currentProfile.data.matchScore = parseInt(matchscore ? matchscore : 0);
                    }

                    setIsFavorited(currentProfile.data.favorited);
                    setProfile(currentProfile.data, setState, false, validateText, false);
                }
            } catch (error) {
                console.log(error);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [personalProfile]);

    useEffect(() => {
        setShowLoginBanner(!isLoggedIn);
    }, [isLoggedIn]);

    if (!state) {
        return (
            <>
                <View className="loading-container-center">
                    <CircularProgress />
                    <Text>Loading user information...</Text>
                </View>
            </>
        );
    }

    return (
        <View className="public-profile-container">
            <View
                style={
                    isMobile
                        ? {
                              height: "92vh",
                              overflow: "scroll",
                          }
                        : {}
                }>
                <Profile
                    id={id}
                    state={{
                        ...state,
                        favorited: isFavorited,
                    }}
                    allowSendMessage={allowSendMessage}
                    isPublicProfile={isPublicProfile}
                />
            </View>
            {showLoginBanner && <LoginBanner />}
        </View>
    );
};

export default PublicProfile;
