import React, { useState } from "react";
import Button from "components/Button";
import "./styles.css";
import { useNavigate } from "react-router-dom";
import { useBreakpoint } from "hooks/useBreakpoint";
import { storage } from "@services/sessionStorage";

export const LoginBanner = ({ disableDeeplink = false }) => {
    let navigate = useNavigate();
    const [open, setOpen] = useState(true);
    const { isMobile } = useBreakpoint();

    const handleSignUp = () => {
        if (disableDeeplink) navigate("/signup");
        else {
            // In case of session storage, we will store the current path in a different key
            storage.sessionStorage.surveyRedirect.setPath();
            // Remove the current path from the session storage
            storage.sessionStorage.redirect.remove();
            navigate("/signup");
        }
    };
    const handleLogIn = () => {
        if (disableDeeplink) navigate("/login");
        else {
            // In case the deeplink is enabled, then we need to store the current path
            storage.sessionStorage.redirect.setPath();
            // Remove the current path from the session storage
            storage.sessionStorage.surveyRedirect.remove();
            navigate("/login");
        }
    };

    if (!open) return null;

    return (
        <div className="login-banner-box">
            <div className="container">
                <button className="close-btn" onClick={() => setOpen(false)}>
                    X
                </button>
                <div className="logo">
                    <img src="/logo-alpha.png" alt="RPGMatch" />
                </div>

                <div className="description">
                    <h2>Log into RPGMatch</h2>
                    <p>
                        {`Hail, wanderers! To access our realm's marvels, a simple task: log in or sign up. ${
                            !isMobile ? "Beyond this gateway await your finest companions." : ""
                        }`}
                    </p>
                </div>

                <div className="buttons">
                    <Button onClick={handleSignUp}>SIGN UP FOR FREE</Button>
                    <Button onClick={handleLogIn} backgroundColor="transparent" labelColor="#A61F38">
                        LOG IN
                    </Button>
                </div>
            </div>
        </div>
    );
};
