import "./Biography.scss";
import React, { useEffect, useMemo, useState } from "react";
import { Text, View } from "@aws-amplify/ui-react";
import { TextField, Typography, useMediaQuery } from "@mui/material";
import _ from "lodash";
import LoadingBtn from "components/LoadingBtn/LoadingBtn";
import { AutoAwesome } from "@mui/icons-material";
import { endpoints } from "services/endpoints";
import { api } from "services/api";

type BioProps = {
    bio: string | undefined;
    changeEditState: (state: any) => void;
};

export const Biography = ({ bio, changeEditState }: BioProps) => {
    const maxLabelWidth = useMediaQuery("(min-width:380px)");

    const [textValue, setTextValue] = useState("");
    const [isLoadingBio, setIsLoadingBio] = useState(false);

    useEffect(() => {
        if (bio) {
            setTextValue(bio);
        }
    }, [bio]);

    const onTextChange = (text: string) => {
        setTextValue(text);
        changeEditState({ bio: text });
    };

    const placeholder = useMemo(() => {
        return maxLabelWidth ? undefined : "Use this field to unleash you epic backstory!";
    }, [maxLabelWidth]);

    const label = useMemo(() => {
        return maxLabelWidth ? "Use this field to unleash you epic backstory!" : undefined;
    }, [maxLabelWidth]);

    const handleGenerateBio = async () => {
        try {
            setIsLoadingBio(true);
            const { data: generatedBio } = await api.post(endpoints.generateBio);
            onTextChange(generatedBio);
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoadingBio(false);
        }
    };

    return (
        <View id="biography">
            <div className="profile-section bio-container">
                <Text className="head-text">Bio</Text>
                <View className="form-container">
                    <View className="form-textarea-button">
                        <TextField
                            fullWidth
                            onChange={(e) => onTextChange(e.target.value)}
                            value={textValue}
                            variant="outlined"
                            label={label}
                            className={`name-component ${textValue.length > 0 ? "filled" : ""} `}
                            inputProps={{ maxLength: 160 }}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                                endAdornment: (
                                    <LoadingBtn
                                        className="generate-bio-btn"
                                        label="Generate bio"
                                        loading={isLoadingBio}
                                        handleOnClick={handleGenerateBio}
                                        icon={<AutoAwesome />}
                                    />
                                ),
                            }}
                            placeholder={placeholder}
                            multiline
                            minRows={4}
                        />
                    </View>
                    <Typography className="characters-text">{`${textValue.length} out of 160 characters`}</Typography>
                </View>
            </div>
        </View>
    );
};
