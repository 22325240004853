import "../../pages/Group/Group.scss";

import React, { Dispatch, SetStateAction, useContext } from "react";

import { Box, CircularProgress } from "@mui/material";

import { GroupsList } from "./GroupsList";
import { ShouldRender } from "@components/Profile/components/ShouldRender/ShouldRender";
import { GroupsNoResults } from "@components/Groups/GroupsNoResults";

import { ProfileModel } from "models/ProfileModels";
import { CMSContextContent } from "models/cms/fileds";

import { useGlobalContext } from "context/AppContext";
import { AsyncFilterFieldsContext } from "context/AsyncFilterFieldsContext";
import { GroupTypeInList } from "models/group/GroupModel";
import { AlertPopUpProps } from "components/AlertPopUp/AlertPopUpSmall";
import { GroupsFilterChips } from "./GroupsFilterChips";

type GroupsProps = {
    ref?: React.RefObject<HTMLDivElement>;
    groups: Array<GroupTypeInList>;
    showPopup: (props: Omit<AlertPopUpProps, "open">, ttl?: number) => void;
    ownedGroups: Array<GroupTypeInList>;
    joinedGroups: Array<GroupTypeInList>;
    discoverGroups: Array<GroupTypeInList>;
    handleJoinGroup: (group: GroupTypeInList) => Promise<void>;
    isLoading: boolean;
    isFetched: boolean;
    loadingCMS: boolean;
    setDefaultFilters: () => void;
    currentFilters: any;
    hasFilter: boolean;
    isLoggedIn: boolean;
    applyFilter: any;
    handleFilters: any;
    setLoginPopup: Dispatch<SetStateAction<boolean>>;
    clearFilter: ({
        preserveFilters,
        removeFilters,
    }: {
        preserveFilters: never[];
        removeFilters: never[];
    }) => Promise<void>;
};

export default function Groups({
    ref,
    groups,
    ownedGroups,
    joinedGroups,
    handleJoinGroup,
    discoverGroups,
    showPopup,
    isFetched,
    isLoading,
    loadingCMS,
    setDefaultFilters,
    hasFilter,
    currentFilters,
    isLoggedIn,
    setLoginPopup,
    clearFilter,
}: GroupsProps) {
    const { filterFields }: CMSContextContent = useContext(AsyncFilterFieldsContext);
    const { personalProfile }: { personalProfile: ProfileModel | undefined } = useGlobalContext();

    return (
        <Box className="groups-list-container" ref={ref}>
            <ShouldRender returnNull condition={ownedGroups.length > 0}>
                <GroupsList.Section title="Your Group">
                    <ShouldRender loading={!groups}>
                        <GroupsList.List>
                            {ownedGroups.map((group) => (
                                <GroupsList.Item
                                    key={`owned-group-${group.id}`}
                                    group={group}
                                    section="HOSTED"
                                    showPopup={showPopup}
                                    filterFields={filterFields}
                                    personalProfile={personalProfile}
                                    handleJoinGroup={handleJoinGroup}
                                />
                            ))}
                        </GroupsList.List>
                    </ShouldRender>
                </GroupsList.Section>
            </ShouldRender>
            <ShouldRender returnNull condition={joinedGroups.length > 0}>
                <GroupsList.Section expanded={true} title="Groups you're in" className="groups-joined-accordion">
                    <ShouldRender loading={!groups}>
                        <GroupsList.List>
                            {joinedGroups.map((group) => (
                                <GroupsList.Item
                                    key={`joined-group-${group.id}`}
                                    group={group}
                                    section="JOINED"
                                    showPopup={showPopup}
                                    filterFields={filterFields}
                                    personalProfile={personalProfile}
                                    handleJoinGroup={handleJoinGroup}
                                />
                            ))}
                        </GroupsList.List>
                    </ShouldRender>
                </GroupsList.Section>
            </ShouldRender>
            <ShouldRender
                loading={(isLoading && groups.length === 0) || loadingCMS}
                alternativeComponent={
                    <GroupsList.Section expanded collapse={false} title="Discover">
                        <GroupsNoResults clearFilters={setDefaultFilters} />
                    </GroupsList.Section>
                }
                alternativeLoadingComponent={
                    <Box className="loading-component">
                        <CircularProgress sx={{ color: "#A61F38" }} />
                    </Box>
                }
                condition={discoverGroups.length > 0}>
                <GroupsList.Section title="Discover" expanded collapse={false}>
                    <ShouldRender loading={!groups}>
                        <GroupsList.List>
                            <GroupsFilterChips
                                clearFilter={clearFilter}
                                currentFilters={currentFilters}
                                filterActive={hasFilter}
                                filterFields={filterFields}
                                setLoginPopup={setLoginPopup}
                                isLoggedIn={isLoggedIn}
                            />
                            {discoverGroups.map((group) => (
                                <GroupsList.Item
                                    key={`discover-group-${group.id}`}
                                    group={group}
                                    section="DISCOVER"
                                    showPopup={showPopup}
                                    filterFields={filterFields}
                                    personalProfile={personalProfile}
                                    handleJoinGroup={handleJoinGroup}
                                />
                            ))}
                        </GroupsList.List>
                    </ShouldRender>
                </GroupsList.Section>
            </ShouldRender>
        </Box>
    );
}
