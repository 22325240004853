import { useState } from "react";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { useSignOut } from "hooks/useSignOut";
import { useSnackbarContext } from "context/SnackbarContext";
import { storage } from "@services/sessionStorage";

export const useAuth = () => {
    const [tempEmailSignup, setTempEmailSignup] = useState("");
    const [authInProgress, setAuthInProgress] = useState(false);
    const [isDone, setIsDone] = useState(false);
    const [passwordStep, setPasswordStep] = useState(1);
    const [message, setMessage] = useState("");
    const [autoSignedIn, setAutoSignedIn] = useState(true);
    const { signOutAndRedirect } = useSignOut();
    const { showSnackbar } = useSnackbarContext();

    let navigate = useNavigate();
    const logIn = async ({ email, password }) => {
        if (!email || email === "") return;
        if (!password || password === "") return;

        setAuthInProgress(true);

        try {
            await Auth.signIn(email, password);

            const authentication = await Auth.currentSession();
            const token = authentication.idToken.jwtToken;
            const decoded = jwtDecode(token);

            if (!decoded?.email_verified) {
                signOutAndRedirect().finally(() => {
                    showSnackbar({
                        title: "Something went wrong",
                        anchor: { vertical: "top", horizontal: "right" },
                        description:
                            "Your registered email was not verified, please contact support (hello@rpgmatch.org).",
                    });
                });
            } else {
                setIsDone(true);
                const deeplink = storage.sessionStorage.redirect;
                const route = deeplink.path;

                if (!!route) {
                    deeplink.remove();
                    navigate(route);
                }
            }
        } catch (err) {
            setAuthInProgress(false);
            if (err.name === "UserNotConfirmedException") {
                setTempEmailSignup(email);
                setAutoSignedIn(false);
                navigate("/signup-confirmation");
            } else {
                setMessage(err.message);
            }
        }
    };

    const pureSignIn = async ({ email, password }) => await Auth.signIn(email, password);

    const signUp = async ({ email, password, name }) => {
        setAuthInProgress(true);
        try {
            setMessage("");
            const { userConfirmed } = await Auth.signUp({
                username: email,
                password: password,
                attributes: { name: name },
                autoSignIn: {
                    enabled: true,
                },
            });

            setAuthInProgress(false);

            if (!userConfirmed) {
                setTempEmailSignup(email);
                setAutoSignedIn(true);
                navigate("/signup-confirmation");
            }
        } catch (err) {
            console.error("❌ Error signing up...", err);
            setAuthInProgress(false);
            setMessage(err.message);
        }
    };

    const confirmSignUp = async ({ code }) => {
        setAuthInProgress(true);
        const email = tempEmailSignup;
        try {
            await Auth.confirmSignUp(email, code);
            if (autoSignedIn) {
                setMessage("Account verified!");
                setIsDone(true);
            } else {
                setAuthInProgress(false);
                setMessage("Account verified. Please, log in.");
            }
        } catch (err) {
            setAuthInProgress(false);
            setMessage(err.message);
            console.error("❌ Error confirming user...", err);
        }
    };
    const resendConfirmationCode = async () => {
        setAuthInProgress(true);
        try {
            await Auth.resendSignUp(tempEmailSignup);
            setAuthInProgress(false);
            setMessage("We’ve sent an account confirmation code to your inbox. Please enter the code below.");
        } catch (err) {
            setMessage(err.message);
            setAuthInProgress(false);
            console.error("❌ Error resending code.", err);
        }
    };
    const sendForgotPasswordCode = async (email) => {
        setAuthInProgress(true);
        try {
            setTempEmailSignup(email);
            await Auth.forgotPassword(email);
            setPasswordStep(2);
            setAuthInProgress(false);
            setMessage("We’ve sent an account confirmation code to your inbox. Please enter the code below.");
        } catch (error) {
            setMessage("Account confirmation code was not sent. Please, try again.");
            setAuthInProgress(false);
            console.error(error.message);
        }
    };
    const recoverPassword = async ({ email, code, password }) => {
        const _email = email || tempEmailSignup;
        setAuthInProgress(true);
        if (_email && code && password) {
            Auth.forgotPasswordSubmit(_email, code, password)
                .then(() => {
                    setMessage("Password reset successfully. Please, log in.");
                    setAuthInProgress(false);
                })
                .catch((err) => {
                    setMessage(err.message);
                    setAuthInProgress(false);
                    console.error("❌ Error trying to recover password.", err);
                });
        }

        if (email && !code && !password) {
            await sendForgotPasswordCode(email)
                .then(() => {
                    setAuthInProgress(false);
                })
                .catch((err) => {
                    setAuthInProgress(false);
                    console.error(err.message);
                });
        }
    };

    const cleanMessage = () => {
        setMessage("");
    };

    return {
        logIn,
        signUp,
        confirmSignUp,
        resendConfirmationCode,
        recoverPassword,
        sendForgotPasswordCode,
        cleanMessage,
        pureSignIn,
        passwordStep,
        tempEmailSignup,
        authInProgress,
        isDone,
        message,
        setMessage,
    };
};
