import React from "react";
import { Text, View } from "@aws-amplify/ui-react";
import "./ProfileAvailability.css";
import Availability from "components/Profile/components/ProfileBody/ProfileAvailability/Availability";
import AvailabilityEdit from "./AvailabilityEdit";
import { ShouldRender } from "../../ShouldRender/ShouldRender";

const ProfileAvailability = ({ state, renderQuestions, isEditing, changeEditState, editState }) => {
    const hasAvaliability = !state?.profile?.noAvailability?.[0];

    return (
        <View id="availability" data-testid="profile-availability" className={`availability-container profile-component ${isEditing ? "edit" : ""}`}>
            <Text as="h2" className="availability-head-text">
                Availability
            </Text>
            {isEditing ? (
                <AvailabilityEdit
                    availabilityTable={state.profile}
                    changeEditState={changeEditState}
                    noAvailability={state?.profile?.noAvailability ? state?.profile?.noAvailability[0] : false}
                    editState={editState}
                />
            ) : (
                <ShouldRender
                    condition={hasAvaliability}
                    alternativeComponent={<Text as="p">This user has no availability.</Text>}>
                    <Availability availabilityTable={state?.profile} isEditing={isEditing} />
                </ShouldRender>
            )}
        </View>
    );
};

export default ProfileAvailability;
