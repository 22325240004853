/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
    InputLabel,
    Select,
    FormControl,
    MenuItem,
    Box,
    Chip,
    Checkbox,
    ListItemText,
    TextField,
    Typography,
    InputAdornment,
} from "@mui/material";
import View from "@mui/material/Box";
import "./ProfileGMOrPlayerSelect.css";
import StartPlayingIcon from "components/Profile/components/StartPlayingIcon";

/*
This form can be used to update the profile player type
*/

const playerTypeOptions = [
    { label: "Player", value: "Player only." },
    { label: "GM", value: "GM only." },
    { label: "Professional GM", value: "professionalGM" },
];

export const ProfileGMOrPlayerSelect = ({ state, changeEditState }) => {
    const hasPlayerTypeState = () => {
        if (!state?.questionnaireTransitionDM) {
            return [];
        }

        if (state?.questionnaireTransitionDM?.includes("Either/Both.")) {
            return [playerTypeOptions[0].value, playerTypeOptions[1].value];
        }

        const values = playerTypeOptions.map((option) => option.value);
        return state?.questionnaireTransitionDM.filter((x) => values.includes(x));
    };

    const hasStartPlayingState = () => {
        if (state?.startplaying?.length > 0) {
            return state.startplaying[0];
        }

        return "";
    };

    const [selectedOptions, setSelectedOptions] = useState(hasPlayerTypeState);
    const [startPlayingValue, setStartPlayingValue] = useState(hasStartPlayingState);

    useEffect(() => {
        if (state?.questionnaireTransitionDM) {
            const currentQTG = hasPlayerTypeState();
            setSelectedOptions(currentQTG);
        }
    }, [state?.questionnaireTransitionDM]);

    useEffect(() => {
        if (state?.startplaying?.length > 0) {
            const currentSP = hasStartPlayingState();
            setStartPlayingValue(currentSP);
        }
    }, [state?.startplaying]);

    const hasProfessionalGMOption = () => {
        return selectedOptions.includes("professionalGM");
    };

    const renderPlayerTypeValues = (selected) => {
        return (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {selected.map((value) => {
                    const option = playerTypeOptions.find((option) => option.value === value);
                    return <Chip key={`player-type-chip-${value}`} label={option.label} />;
                })}
            </Box>
        );
    };

    const handleChangeOptions = (event) => {
        const value = event.target.value;
        const editState = { questionnaireTransitionDM: value };

        if (hasProfessionalGMOption()) {
            if (!value.includes("professionalGM")) {
                setStartPlayingValue("");
                editState.startplaying = "";
            } else {
                editState.startplaying = state?.startplaying;
            }
        }

        setSelectedOptions(value);
        changeEditState(editState);
    };

    const handleChangeText = (e) => {
        setStartPlayingValue(e.target.value);
        changeEditState({ startplaying: e.target.value, questionnaireTransitionDM: selectedOptions });
    };

    return (
        <>
            <View className="form-container">
                <FormControl fullWidth>
                    <InputLabel id="player-type-label">Player Type</InputLabel>
                    <Select
                        className="filter-input"
                        label="Player Type"
                        id="player-type"
                        data-testid="player-type-select"
                        multiple
                        value={selectedOptions}
                        onChange={handleChangeOptions}
                        renderValue={renderPlayerTypeValues}
                        inputProps={{ className: "player-type-input", "data-testid": "player-type-input" }}>
                        {playerTypeOptions.map((option) => (
                            <MenuItem
                                key={`player-type-${option.label}`}
                                data-testid="player-type-option"
                                value={option.value}>
                                <Checkbox checked={selectedOptions.findIndex((op) => op === option.value) !== -1} />
                                <ListItemText primary={option.label} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </View>
            <View className="form-container startplaying-input">
                <Typography className="startplaying-label">
                    To unlock this secret class, you must first verify your StartPlaying credentials
                </Typography>
                <TextField
                    fullWidth
                    value={startPlayingValue}
                    onChange={handleChangeText}
                    variant="standard"
                    placeholder="StartPlaying Username"
                    data-testid="startplaying-textfield"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <StartPlayingIcon hasTooltip={false} />
                            </InputAdornment>
                        ),
                        inputProps: {
                            "data-testid": "startplaying-input",
                            "aria-disabled": !hasProfessionalGMOption(),
                        },
                    }}
                    disabled={!hasProfessionalGMOption()}
                />
            </View>
        </>
    );
};
export default ProfileGMOrPlayerSelect;
