import "./UserList.css";
import { SortContext } from "context/SortContext";
import React, { useEffect, useContext, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { FilterContext, defaultFilters } from "context/FilterContext";
import { useGlobalContext } from "context/AppContext";
import { useFilter } from "hooks/useFilter";
import { typesOfSort, useSort } from "hooks/useSort";
import UserListComponent from "components/UserList/UserListComponent";
import { AsyncFilterFieldsContext } from "context/AsyncFilterFieldsContext";
import { usePaginatedContext } from "context/PaginatedUserListContext";
import { useFavorites } from "hooks/useFavorites";
import { useQueryClient } from "react-query";
import { isEqual } from "lodash";

export const sortingOptions = typesOfSort.map((x) => x.displayName);

const HomePage = ({ title }) => {
    const location = useLocation();
    const queryClient = useQueryClient();
    const { listOrder } = useContext(SortContext);

    const {
        userList,
        scrollTarget,
        onTargetScroll,
        isFetchingPage,
        setUsers,
        reloadFavorites,
        isChangingPage,
        favoritesToDelete,
        setFavoritesToDelete,
    } = usePaginatedContext();

    const { isFiltering, setFilters, setPage, setCurrentFilters, setPreviousFilters, setHasFilter } =
        useContext(FilterContext);
    const { updateFavorites, wait } = useFavorites({
        allProfiles: userList,
        setAllProfiles: setUsers,
        reloadFavorites,
        favoritesToDelete,
        setFavoritesToDelete,
    });

    const isUserList = useMemo(() => location.pathname === "/user-list", [location.pathname]);
    const isStarredList = useMemo(() => location.pathname === "/starred", [location.pathname]);

    useEffect(() => {
        document.title = title;
        const cache = queryClient.getQueryCache();
        let starred = [];
        let home = [];
        cache.queries.forEach((itemQuery) => {
            if (itemQuery?.state?.data?.data?.length > 0) {
                if (itemQuery.queryKey.includes("starred")) {
                    const dataToUpdate = itemQuery?.state?.data?.data.filter(
                        (item) => !favoritesToDelete.find((x) => x?.[0] === (item?.id?.[0] || item?.id)),
                    );
                    starred.push(dataToUpdate);
                    return;
                }
                const findFilters = itemQuery?.queryKey?.find((item) => isEqual(item, currentFilters));

                if (itemQuery.queryKey.includes(listOrder) && !!findFilters) {
                    home.push(itemQuery?.state?.data?.data);
                }
            }
        });

        let homeArray = [].concat.apply([], home);
        let starredArray = [].concat.apply([], starred);

        homeArray = homeArray.map((item) => {
            const found = favoritesToDelete.find((x) => x?.[0] === (item?.id?.[0] || item?.id));

            if (found) return { ...item, favorited: false };
            else return item;
        });

        const sortingOptionsMap = {
            "Join Date": {
                value: undefined,
                checked: (option) => option === undefined || option === "",
            },
            "Match Score": {
                value: "match",
                checked: (option) => option === "match",
            },
        };

        try {
            if (isStarredList) {
                setUsers([...starredArray]);
                setFilters(defaultFilters);
                setHasFilter(false);
                setPreviousFilters({ ...currentFilters });
                setCurrentFilters(defaultFilters);
            }

            if (isUserList) {
                setUsers([...homeArray]);
                const sort = sortingOptionsMap[listOrder];
                const sortValue = sort?.value || "";
                setFilters((oldFilters) => ({ ...oldFilters, sort: sortValue }));
                setHasFilter(false);
                setPreviousFilters(() => ({ ...currentFilters }));
                setCurrentFilters((oldFilters) => ({ ...oldFilters, sort: sortValue }));
            }
        } catch (error) {
            console.log(error);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    /** HOOKS */

    const foundUsers = userList && userList.length > 0;

    const { isLoggedIn, userCompletedProfile } = useGlobalContext();
    const { filterFields, loading: loadingData, isSuccess } = useContext(AsyncFilterFieldsContext);

    const {
        filters,
        clearFilter,
        applyFilter,
        usernameValues,
        setUsernameVal,
        showFilter,
        setShowFilter,
        handleFilters,
        hasFilter,
        setDefaultFilters,
        currentFilters,
        onBackFilter,
        generateQueryParam,
    } = useFilter({
        isUserList,
        filterFields,
    });

    const { anchorEl, isSortMenuOpen, handleOpenSortMenu, handleCloseSortMenu, onSelectSort, sortingOption } = useSort({
        sortingOptions,
        setUsers,
        setFilters,
        setPage,
    });

    const sortState = {
        sortingOption,
        sortingOptions,
        anchorEl,
        isSortMenuOpen,
        handleOpenSortMenu,
        handleCloseSortMenu,
        onSelectSort,
    };

    const filterState = {
        filters,
        clearFilter,
        applyFilter,
        usernameValues,
        setUsernameVal,
        showFilter,
        setShowFilter,
        handleFilters,
        hasFilter,
        isFiltering,
        setDefaultFilters,
        currentFilters,
        onBackFilter,
        generateQueryParam,
    };

    // React-Router
    return (
        <UserListComponent
            userList={userList}
            foundUsers={foundUsers}
            wait={wait}
            updateFavorites={updateFavorites}
            setUsers={setUsers}
            userCompletedProfile={userCompletedProfile}
            isLoggedIn={isLoggedIn}
            filterState={filterState}
            sortState={sortState}
            setDefaultFilters={setDefaultFilters}
            scrollTarget={scrollTarget}
            onTargetScroll={onTargetScroll}
            isFetchingPage={isFetchingPage}
            loadingFiltersFields={loadingData || !isSuccess}
            filterFields={filterFields}
            isChangingPage={isChangingPage}
            favoritesToDelete={favoritesToDelete}
            sortingFilterIntegrated={true}
        />
    );
};

export default HomePage;
