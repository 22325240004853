import "./PersonalInformation.css";
import { Text, View } from "@aws-amplify/ui-react";
import ProfileAge from "components/ProfileInterview/ProfileAge";
import ProfileGMOrPlayerSelect from "components/ProfileInterview/ProfileGMOrPlayerSelect";
import ProfileGenderSelect from "components/ProfileInterview/ProfileGenderSelect";
import ProfileName from "components/ProfileInterview/ProfileName";
import React from "react";
import About from "../About/About";
import ProfileTimezone from "components/ProfileInterview/ProfileTimezone";
import { ProfileLocation } from "components/ProfileInterview/ProfileLocation";
import {ProfileChangeEmailWrapper} from "components/ProfileChangeEmail";

const PersonalInformation = ({ profile, changeEditState, isEditing, isPublicProfile }) => {
    return (
        <View
            id="about"
            data-testid="personal-information"
            className={isEditing ? "profile-section personal-info-container" : "personal-info-container"}>
            {isEditing ? (
                <>
                    <Text className="head-text" as="h2">
                        About
                    </Text>
                    <View className="container-row">
                        <ProfileName state={profile} isEditing={isEditing} changeEditState={changeEditState} />
                        <ProfileAge state={profile} isEditing={isEditing} changeEditState={changeEditState} />
                    </View>
                    <View className="container-row">
                        <ProfileGMOrPlayerSelect state={profile} changeEditState={changeEditState} />
                    </View>
                    <View className="container-row">
                        <ProfileGenderSelect state={profile} changeEditState={changeEditState} />
                    </View>
                    {/* About Me is removed until back-end is done */}
                    {/* <View className="container-row">
                        <ProfileAboutMe state={profile} changeEditState={changeEditState} />
                    </View> */}
                    <View className="container-row">
                        <ProfileLocation state={profile} isEditing={isEditing} changeEditState={changeEditState} />
                    </View>
                    <View className="container-row">
                        <ProfileTimezone state={profile} isEditing={isEditing} changeEditState={changeEditState} />
                    </View>
                    <View className="container-row">
                        <ProfileChangeEmailWrapper />
                    </View>
                </>
            ) : (
                <About state={profile} isPublicProfile={isPublicProfile} />
            )}
        </View>
    );
};

export default PersonalInformation;
