import "../Home/UserList.css";
import React, { useEffect, useContext, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { FilterContext, defaultFilters } from "context/FilterContext";
import { useGlobalContext } from "context/AppContext";
import { useFilter } from "hooks/useFilter";
import { typesOfSort, useSort } from "hooks/useSort";
import UserListComponent from "components/UserList/UserListComponent";
import { AsyncFilterFieldsContext } from "context/AsyncFilterFieldsContext";
import { usePaginatedContext } from "context/PaginatedUserListContext";
import { useFavorites } from "hooks/useFavorites";
import { useQueryClient } from "react-query";
import { LoginContext } from "context/LoginContext";
import { LoginBanner } from "@components/LoginBanner/LoginBanner";
import { saveRedirect } from "utils/functions";

export const sortingOptions = typesOfSort.map((x) => x.displayName);

const PublicLocationUserList = () => {
    const location = useLocation();
    const queryClient = useQueryClient();
    const {
        userList,
        scrollTarget,
        onTargetScroll,
        isFetchingPage,
        setUsers,
        reloadFavorites,
        isChangingPage,
        favoritesToDelete,
        setFavoritesToDelete,
        locationStatus,
    } = usePaginatedContext();
    const { loginPopup, setLoginPopup } = useContext(LoginContext);
    const { isFiltering, setFilters, setPage, setCurrentFilters } = useContext(FilterContext);
    const [showLoginBanner, setShowLoginBanner] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const { updateFavorites, wait } = useFavorites({
        allProfiles: userList,
        setAllProfiles: setUsers,
        reloadFavorites,
        favoritesToDelete,
        setFavoritesToDelete,
    });

    useEffect(() => {
        const cache = queryClient.getQueryCache();
        let starred = [];
        let home = [];
        cache.queries.forEach((itemQuery) => {
            if (itemQuery?.state?.data?.data?.length > 0) {
                if (itemQuery.queryKey.includes("starred")) {
                    const dataToUpdate = itemQuery?.state?.data?.data.filter(
                        (item) => !favoritesToDelete.find((x) => x?.[0] === (item?.id?.[0] || item?.id)),
                    );
                    starred.push(dataToUpdate);
                    return;
                }
                home.push(itemQuery?.state?.data?.data);
            }
        });

        let homeArray = [].concat.apply([], home);
        let starredArray = [].concat.apply([], starred);

        homeArray = homeArray.map((item) => {
            const found = favoritesToDelete.find((x) => x?.[0] === (item?.id?.[0] || item?.id));

            if (found) return { ...item, favorited: false };
            else return item;
        });

        if (location.pathname === "/starred") {
            setUsers([...starredArray]);
            setFilters(defaultFilters);
            setCurrentFilters(defaultFilters);
        }

        if (location.pathname === "/user-list") {
            setUsers([...homeArray]);
            setFilters(defaultFilters);
            setCurrentFilters(defaultFilters);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    useEffect(() => {
        const param = searchParams.get("matchscore");
        if (param) {
            searchParams.delete("matchscore");
            setSearchParams(searchParams);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /** HOOKS */

    const foundUsers = userList && userList.length > 0;

    const { isLoggedIn, userCompletedProfile } = useGlobalContext();
    const { filterFields, loading: loadingData, isSuccess } = useContext(AsyncFilterFieldsContext);

    const {
        filters,
        clearFilter,
        applyFilter,
        usernameValues,
        setUsernameVal,
        showFilter,
        setShowFilter,
        handleFilters,
        hasFilter,
        setDefaultFilters,
        currentFilters,
        generateQueryParam,
    } = useFilter({
        filterFields,
        publicLocation: true,
    });

    const { anchorEl, isSortMenuOpen, handleOpenSortMenu, handleCloseSortMenu, onSelectSort, sortingOption } = useSort({
        sortingOptions,
        setUsers,
        setFilters,
        setPage,
    });

    const sortState = {
        sortingOption,
        sortingOptions,
        anchorEl,
        isSortMenuOpen,
        handleOpenSortMenu,
        handleCloseSortMenu,
        onSelectSort,
    };

    const filterState = {
        filters,
        clearFilter,
        applyFilter,
        usernameValues,
        setUsernameVal,
        showFilter,
        setShowFilter,
        handleFilters,
        hasFilter,
        isFiltering,
        setDefaultFilters,
        currentFilters,
        generateQueryParam,
    };

    useEffect(() => {
        if (locationStatus === "success") {
            setShowLoginBanner(!isLoggedIn);
        }
    }, [isLoggedIn, locationStatus]);

    return (
        <>
            <UserListComponent
                userList={userList}
                foundUsers={foundUsers}
                wait={wait}
                updateFavorites={updateFavorites}
                setUsers={setUsers}
                userCompletedProfile={userCompletedProfile}
                isLoggedIn={isLoggedIn}
                filterState={filterState}
                sortState={sortState}
                setDefaultFilters={setDefaultFilters}
                scrollTarget={scrollTarget}
                onTargetScroll={onTargetScroll}
                isFetchingPage={isFetchingPage}
                loadingFiltersFields={loadingData || !isSuccess}
                filterFields={filterFields}
                isChangingPage={isChangingPage}
                favoritesToDelete={favoritesToDelete}
                loginPopup={loginPopup}
                setLoginPopup={setLoginPopup}
                sortingFilterIntegrated={true}
                title="User List"
            />
            {showLoginBanner && <LoginBanner />}
        </>
    );
};

export default PublicLocationUserList;
