import { SortContext } from "context/SortContext";
import { useContext } from "react";
import { useSearchParams } from "react-router-dom";
import { filterSearchParams, paramsToObject } from "utils/functions";
import { useLocation } from "react-router-dom";

export const typesOfSort = [
    { displayName: "Join Date", urlParam: "", apiParam: "" },
    { displayName: "Match Score", urlParam: "match", apiParam: "sort" },
];

export const useSort = ({ setFilters, setPage, setUsers }) => {
    const location = useLocation();
    const { anchorEl, setAnchorEl, sortingOption, setSortingOption, isSortMenuOpen, setListOrder } =
        useContext(SortContext);
    const [searchParams, setSearchParams] = useSearchParams();
    const handleOpenSortMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseSortMenu = () => {
        setAnchorEl(null);
    };

    const onSelectSort = async (index) => {
        const actualSearchParams = searchParams.entries();
        const parsedSearchParams = paramsToObject(actualSearchParams);
        const selectedSort = typesOfSort[index];
        const pageNumberStorageItemName = `${location.pathname.replace("/", "")}_pageNumber`;
        const scrollStorageItemName = `${location.pathname.replace("/", "")}_initialScrollPosition`;

        if (!selectedSort) {
            console.error("No type of sort found on typesOfSort array");
            return;
        }

        setSortingOption(selectedSort.displayName);
        setListOrder(selectedSort.displayName);
        const filteredSearchParams = filterSearchParams(parsedSearchParams);

        const paramState = {
            ...filteredSearchParams,
        };
        if (selectedSort.urlParam) {
            paramState.sort = selectedSort.urlParam;
        } else {
            delete paramState.sort;
        }

        const URLParams = new URLSearchParams(paramState);

        setSearchParams(URLParams);
        /**
         * Cleaning the user state before changing sorts
         */
        setPage(1);
        setUsers([]);
        sessionStorage.setItem(pageNumberStorageItemName, String(1));
        sessionStorage.setItem(scrollStorageItemName, String(0));
        /**
         * Remove sort filters for the query if the sort key is empty
         */
        setFilters((oldFilters) => {
            if (oldFilters) {
                const old = oldFilters;
                if (selectedSort.urlParam) {
                    old.sort = selectedSort.urlParam;
                } else {
                    delete old.sort;
                }
                return old;
            }
        });
        handleCloseSortMenu();
    };

    return {
        anchorEl,
        sortingOption,
        isSortMenuOpen,
        handleOpenSortMenu,
        handleCloseSortMenu,
        onSelectSort,
    };
};
