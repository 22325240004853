import "./index.css";

import { View } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css"; // default theme
import CssBaseline from "@mui/material/CssBaseline";

import AboutUsPage from "@pages/AboutUs";
import CompleteProfilePage from "@pages/CompleteProfile";
import PersonalProfile from "@pages/PersonalProfile";
import PrivacyPolicyPage from "@pages/PrivacyPolicy";
import PublicProfile from "@pages/PublicProfile";
import { AuthenticatedRoute } from "AuthenticatedRoute";
import { AppContext, useGlobalContext } from "context/AppContext";

import SignInSection from "@pages/SignInSection";
import React, { useMemo } from "react";
import { unstable_HistoryRouter as Router, Route, Routes } from "react-router-dom";
import history from "./utils/history";

import { PageWrapper } from "@pages/PageWrapper";
import HomePage from "@pages/Home";
import CommunityGuideLines from "@pages/CommunityGuidelines";
import IframeLogin from "./pages/WordPressIframeLogin";
import CompleteProfileRedirect from "pages/CompleteProfile/CompleteProfileRedirect";
import MatchScore from "pages/MatchScore/MatchScore";
import Settings from "pages/Settings";
import ErrorScreen from "pages/ErrorScreen/ErrorScreen";
import Redirect from "pages/Home/RedirectToHome";
import { Inbox } from "./pages/Inbox/Inbox";
import SEOMiddleWare from "components/SEO/SEOMiddleWare";
import ExplorePage from "pages/Explore/ExplorePage";
import PublicGame from "pages/Game/PublicGame";
import PublicLocationUserList from "pages/Location/PublicLocationUserList";

import Group from "pages/Group/Group.tsx";
import ManageGroup from "@pages/Group/ManageGroup";
import GroupsPage from "@pages/Groups/GroupsPage";

/* todo for Andre: insert a "page-level" classname alongside app-root */

// import { lazy, Suspense } from "react";

/* const HomePage = lazy(() => import("pages/Home"));
const CompleteProfilePage = lazy(() => import("pages/CompleteProfile"));
const AboutUsPage = lazy(() => import("pages/AboutUs"));
const PrivacyPolicyPage = lazy(() => import("pages/PrivacyPolicy"));
const CommunityGuideLines = lazy(() => import("pages/CommunityGuidelines"));
const Inbox = lazy(() => import("pages/Inbox/Inbox"));
 */

const ProfileAuthenticatedRoute = () => {
    return (
        <AuthenticatedRoute>
            <PageWrapper>
                <SEOMiddleWare route="personal">
                    <PersonalProfile title="Your Profile - RPGMatch" />
                </SEOMiddleWare>
            </PageWrapper>
        </AuthenticatedRoute>
    );
};

const ProfileRoute = () => {
    const { personalProfile } = useGlobalContext();

    const vanityUrl = useMemo(() => {
        let hrefSplit = window.location.href.split("/");
        hrefSplit = hrefSplit[hrefSplit.length - 1].split("?");

        return hrefSplit[0];
    }, []);

    return vanityUrl !== personalProfile?.vanityUrl?.[0] ? (
        <PageWrapper>
            <SEOMiddleWare route="public">
                <PublicProfile title="User Profile - RPGMatch" />
            </SEOMiddleWare>
        </PageWrapper>
    ) : (
        <ProfileAuthenticatedRoute />
    );
};

const RedirectProfile = () => {
    const { personalProfile, isLoggedIn } = useGlobalContext();

    const vanityUrl = useMemo(() => {
        return personalProfile?.vanityUrl?.[0];
    }, [personalProfile?.vanityUrl]);

    if (!isLoggedIn) {
        return <ProfileAuthenticatedRoute />;
    }

    return !vanityUrl ? null : <Redirect to={`/profile/${vanityUrl}`} />;
};

const AppRoutes = () => {
    return (
        <Router history={history}>
            <AppContext>
                <View className="app-root">
                    <View className="app-body">
                        <CssBaseline>
                            <Routes>
                                <Route
                                    path="/login"
                                    element={
                                        <AuthenticatedRoute alternativeRoute="/profile">
                                            <SignInSection key={"SignInSection"} />
                                        </AuthenticatedRoute>
                                    }
                                />
                                <Route
                                    path="/signup"
                                    element={
                                        <AuthenticatedRoute alternativeRoute="/profile">
                                            <SignInSection key={"SignInSection"} />
                                        </AuthenticatedRoute>
                                    }
                                />
                                <Route
                                    path="/password-recovery"
                                    element={
                                        <AuthenticatedRoute alternativeRoute="/profile">
                                            <SignInSection key={"SignInSection"} />
                                        </AuthenticatedRoute>
                                    }
                                />
                                <Route
                                    path="/signup-confirmation"
                                    element={
                                        <AuthenticatedRoute alternativeRoute="/profile">
                                            <SignInSection key={"SignInSection"} />
                                        </AuthenticatedRoute>
                                    }
                                />
                                <Route
                                    path="/user-list"
                                    element={
                                        <AuthenticatedRoute>
                                            <PageWrapper>
                                                <HomePage title="Users - RPGMatch" />
                                            </PageWrapper>
                                        </AuthenticatedRoute>
                                    }
                                />

                                <Route path="/" element={<Redirect to="/user-list" />} />
                                <Route
                                    path="/starred"
                                    element={
                                        <AuthenticatedRoute>
                                            <PageWrapper>
                                                <HomePage title="Users - RPGMatch" />
                                            </PageWrapper>
                                        </AuthenticatedRoute>
                                    }
                                />
                                <Route
                                    path="/explore"
                                    element={
                                        <PageWrapper>
                                            <SEOMiddleWare route="explore">
                                                <ExplorePage title="Explore - RPGMatch" />
                                            </SEOMiddleWare>
                                        </PageWrapper>
                                    }
                                />
                                <Route
                                    path="/users"
                                    element={
                                        <AuthenticatedRoute>
                                            <PageWrapper>
                                                <HomePage title="Users - RPGMatch" />
                                            </PageWrapper>
                                        </AuthenticatedRoute>
                                    }
                                />
                                <Route
                                    path="/about"
                                    element={
                                        <PageWrapper>
                                            <AboutUsPage title="About Us - RPGMatch" />
                                        </PageWrapper>
                                    }
                                />
                                <Route
                                    path="/privacy"
                                    element={
                                        <PageWrapper>
                                            <PrivacyPolicyPage title="Privacy Policy - RPGMatch" />
                                        </PageWrapper>
                                    }
                                />
                                <Route
                                    path="/community-guidelines"
                                    element={
                                        <PageWrapper>
                                            <CommunityGuideLines title="Community Guidelines - RPGMatch" />
                                        </PageWrapper>
                                    }
                                />
                                <Route path="/profile/:vanityUrl" element={<ProfileRoute />} />
                                <Route path="/profile" element={<RedirectProfile />} />
                                <Route path="/createprofile" element={<CompleteProfileRedirect />} />
                                <Route
                                    path="/edit-profile"
                                    element={
                                        <AuthenticatedRoute saveRedirect={true}>
                                            <PageWrapper>
                                                <PersonalProfile title="RPG MATCH | Your Profile" />
                                            </PageWrapper>
                                        </AuthenticatedRoute>
                                    }
                                />
                                <Route
                                    path="/matchscore"
                                    element={
                                        <AuthenticatedRoute>
                                            <MatchScore />
                                        </AuthenticatedRoute>
                                    }
                                />
                                <Route path="/createprofile" element={<CompleteProfileRedirect />} />
                                <Route
                                    path="/createprofile/:step"
                                    element={
                                        <AuthenticatedRoute>
                                            <PageWrapper hideMenu={true}>
                                                <CompleteProfilePage title="Hey stranger... - RPGMatch" />
                                            </PageWrapper>
                                        </AuthenticatedRoute>
                                    }
                                />
                                <Route
                                    path="/messages"
                                    element={
                                        <AuthenticatedRoute saveRedirect={true}>
                                            <PageWrapper isMessagePage>
                                                <Inbox title="Inbox" />
                                            </PageWrapper>
                                        </AuthenticatedRoute>
                                    }
                                />
                                <Route
                                    path="/messages/:id"
                                    element={
                                        <AuthenticatedRoute saveRedirect={true}>
                                            <PageWrapper isMessagePage>
                                                <Inbox title="Inbox" />
                                            </PageWrapper>
                                        </AuthenticatedRoute>
                                    }
                                />
                                <Route path="/login-wordpress" element={<IframeLogin title="Login" />} />
                                <Route
                                    path="/settings"
                                    element={
                                        <AuthenticatedRoute saveRedirect={true}>
                                            <PageWrapper>
                                                <Settings title="Login" />
                                            </PageWrapper>
                                        </AuthenticatedRoute>
                                    }
                                />
                                {/* Adding catch all Route here */}
                                <Route
                                    path="/location/*"
                                    element={
                                        <PageWrapper showCardsForPage="location">
                                            <SEOMiddleWare route="location">
                                                <PublicLocationUserList />
                                            </SEOMiddleWare>
                                        </PageWrapper>
                                    }
                                />
                                <Route path="/*" element={<ErrorScreen />} />
                                <Route path="/error" element={<ErrorScreen />} />
                                <Route
                                    path="/game/:gameName"
                                    element={
                                        <PageWrapper showCardsForPage="game">
                                            <PublicGame />
                                        </PageWrapper>
                                    }
                                />
                                <Route
                                    path="/create-group"
                                    element={
                                        <AuthenticatedRoute>
                                            <PageWrapper>
                                                <ManageGroup title="Create Group | RPGMatch" variant="CREATE" />
                                            </PageWrapper>
                                        </AuthenticatedRoute>
                                    }
                                />
                                <Route
                                    path="/edit-group"
                                    element={
                                        <AuthenticatedRoute>
                                            <PageWrapper>
                                                <ManageGroup title="Edit Group | RPGMatch" variant="EDIT" />
                                            </PageWrapper>
                                        </AuthenticatedRoute>
                                    }
                                />
                                <Route
                                    path="/group/:groupId"
                                    element={
                                        <PageWrapper>
                                            <Group />
                                        </PageWrapper>
                                    }
                                />
                                <Route
                                    path="/groups"
                                    element={
                                        <PageWrapper>
                                            <SEOMiddleWare route="groups">
                                                <GroupsPage />
                                            </SEOMiddleWare>
                                        </PageWrapper>
                                    }
                                />
                            </Routes>
                        </CssBaseline>
                    </View>
                    <View className="app-footer"></View>
                </View>
            </AppContext>
        </Router>
    );
};

export default AppRoutes;
